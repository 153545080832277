import {useAuth} from "react-oidc-context";
import Title from "antd/es/skeleton/Title";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {getLoggedInUser} from "../../store/app/app.slice";

function OIDCLogin(props: React.PropsWithChildren<OIDCLoginProps>) {
    const { loggedInUser, isLoggedInUserLoading } = useSelector((state) => state.app);
    const dispatch = useDispatch();
    const auth = useAuth();

    useEffect(() => {
        if(auth.isAuthenticated && (auth.error === null || auth.error === undefined))
        {
            dispatch(getLoggedInUser({}));
        }
    }, [auth]);

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    console.log("auth: ", auth);

    if (auth.isLoading || isLoggedInUserLoading) {
        return <div>Loading Auth...</div>;
    }

    if (auth.isAuthenticated && auth.error) {
        auth.signoutRedirect({post_logout_redirect_uri: window.location.href})
    }

    if (auth.error) {
        return (
            <div>
                <Title>Oops ...</Title>
                <Title level={2}>{auth.error.message}</Title>
            </div>
        )
    }

    if (!auth.isAuthenticated && props.forceLoginRedirect) {
        auth.signinRedirect();

    }
    else if(isLoggedInUserLoading || !loggedInUser) {
        return <div>Loading Userdata...</div>;
    }
    else{
        return props.children;

    }
}

interface OIDCLoginProps {
    children?: React.ReactNode,
    forceLoginRedirect: boolean
}

OIDCLogin.propTypes = {
    children: PropTypes.node.isRequired,
    forceLoginRedirect: PropTypes.bool.isRequired
};
export default OIDCLogin;