import { useAuth } from "react-oidc-context"
import {getAuthenticatedUser, getUserRoles} from "../../utils/authHelper";
import {Redirect} from "react-router-dom";
import OIDCLogin from "../../components/login/OIDCLogin";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import environments from "../../utils/environments";

function ProtectedRoute({ element, roles }) {
  const auth = useAuth();
  const { loggedInUser} = useSelector((state) => state.app); 
  const rolesToCheck = roles || ["amj-portal-member","amj-portal-supervisor"];
  
  if(!auth.isAuthenticated || !getAuthenticatedUser().isAccountVerified() || getUserRoles().filter(role => rolesToCheck.includes(role)).length === 0)
  {
    //return element
    if(loggedInUser?.approvalStatus === "NEW_REGISTERED_ACCOUNT" || loggedInUser?.approvalStatus === "APPROVAL_REQUESTED")
    {
      return  <Redirect to={"/accounts"}/>
    }else {
      return  <Redirect to={"/error"}/>
    }
  }
  else{
    return element     
  }
}

ProtectedRoute.propTypes = {
  element: PropTypes.node.isRequired,
};

export default ProtectedRoute;
