import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import accountRestService from "../../services/acc-rest.service";
import letterMgmtRestService from "../../services/letter-mgmt-rest.service";
import {SearchCriteria} from "ps-letter-mgmtrest-client";


const initialState = {
    isUserLoading: false,
    loggedInUser: null,
    letters: [],
    summaries: [],
    answers: [],
    lettersPresentToHudhur: [],
    assignedStacks: [],
    deliveredStacks: [],
    closedStacks: [],
    notAnsweredStacks: [],
    staffMembers: [],
    stackRequests: [],
    lettersByStack: null,
    currentStack: null,
    currentLetter: null,
    allStacks: [],
    myStacks: {
        FILTRATION: [],
        SUMMARY: [],
        ANSWER: []
        
    }
}

export const getLoggedInUser: any = createAsyncThunk(
    "app/getLoggedInUser",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await accountRestService.loadMe();
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadLetters: any = createAsyncThunk(
    "app/loadLetters",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadLetters();
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);


export const loadStaffMembers: any = createAsyncThunk(
    "app/loadStaffMembers",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadStaffMembers();
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadLetter: any = createAsyncThunk(
    "app/loadLetter",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadLetter(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const updateLetter: any = createAsyncThunk(
    "app/updateLetter",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.updateLetter(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadMyStacks: any = createAsyncThunk(
    "app/loadMyStacks",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadMyStacks();
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return {
                FILTRATION: response.filter(stackDto => stackDto.assignedStackType === "FILTRATION" || (stackDto.assignedStackType === null && stackDto.requestedStackType === "FILTRATION")),
                SUMMARY: response.filter(stackDto => stackDto.assignedStackType === "SUMMARY" || (stackDto.assignedStackType === null && stackDto.requestedStackType === "SUMMARY")),
                ANSWER: response.filter(stackDto => stackDto.assignedStackType === "ANSWER" || (stackDto.assignedStackType === null && stackDto.requestedStackType === "ANSWER"))

            };
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const createStackRequest: any = createAsyncThunk(
    "app/createStackRequest",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.createStackRequest(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const createSummary: any = createAsyncThunk(
    "app/createSummary",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.createSummary(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadSummaries: any = createAsyncThunk(
    "app/loadSummaries",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadSummaries(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const createAnswer: any = createAsyncThunk(
    "app/createAnswer",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.createAnswer(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadAnswers: any = createAsyncThunk(
    "app/loadAnswers",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadAnswers(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const assignStackRequest: any = createAsyncThunk(
    "app/assignStackRequest",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.assignStack(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const reassignStack: any = createAsyncThunk(
    "app/reassignStack",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.reassignStack(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const unassignStack: any = createAsyncThunk(
    "app/unassignStack",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.unassignStack(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const deliverStack: any = createAsyncThunk(
    "app/deliverStack",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.deliverStack(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const downloadStackAttachment: any = createAsyncThunk(
    "app/downloadStackAttachment",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.downloadStackAttachment(finalData);
            
                if (response.status === 200) {
                    const contentDisposition = response.headers["content-disposition"]
                    const filename = contentDisposition ? contentDisposition.split("=")[1] : "Stack-"+finalData.stackId+"-attachments.zip";
                    const FileDownload = require('js-file-download');
                    FileDownload(response.data, filename);
                    if (typeof callbackSuccess === 'function')
                        callbackSuccess(response)
                } else {
                    if (typeof callbackSuccess === 'function')
                        callbackError(JSON.stringify(response))
                }
                return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const downloadAttachment: any = createAsyncThunk(
    "app/downloadAttachment",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.downloadAttachment(finalData);

            if (response.status === 200) {
                const contentDisposition = response.headers["content-disposition"]
                const filename = contentDisposition ? contentDisposition.split("=")[1] : "Letter-"+finalData.id+"-attachment.docx";
                const FileDownload = require('js-file-download');
                FileDownload(response.data, filename);
                if (typeof callbackSuccess === 'function')
                    callbackSuccess(response)
            } else {
                if (typeof callbackSuccess === 'function')
                    callbackError(JSON.stringify(response))
            }
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const downloadLettersForHudhur: any = createAsyncThunk(
    "app/downloadLettersForHudhur",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.downloadForLetterForHudhur(finalData);

            if (response.status === 200) {
                const contentDisposition = response.headers["content-disposition"]
                const filename = contentDisposition ? contentDisposition.split("=")[1] : "Letters-to-Huzoor.docx";
                const FileDownload = require('js-file-download');
                FileDownload(response.data, filename);
                if (typeof callbackSuccess === 'function')
                    callbackSuccess(response)
            } else {
                if (typeof callbackSuccess === 'function')
                    callbackError(JSON.stringify(response))
            }
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const submitStack: any = createAsyncThunk(
    "app/submitStack",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.submitStack(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const closeStack: any = createAsyncThunk(
    "app/closeStack",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.closeStack(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const searchLettersToPresentToHudhur: any = createAsyncThunk(
    "app/searchLettersToPresentToHudhur",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            finalData = {}
            const searchCriteriaInternalStatus: SearchCriteria = {
                filterKey: "internalStatus",
                value: "LETTER_PRESENTED_TO_HUDHUR",
                operation: "eq",
                dataOption: "ALL"
            }
            const searchCriteriaStackStatus: SearchCriteria = {
                filterKey: "filtrationStack.status",
                value: "CLOSED",
                operation: "eq",
                dataOption: "ALL"
            }
            finalData.search = {
                searchCriteriaList: [
                    searchCriteriaInternalStatus
                ],
                dataOption: "ALL"
            };
            const response = await letterMgmtRestService.searchLetters(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadLettersByStack: any = createAsyncThunk(
    "app/loadLettersByStack",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadLettersByStack(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadStack: any = createAsyncThunk(
    "app/loadStack",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.getStack(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadAssignedStacks: any = createAsyncThunk(
    "app/loadAssignedStacks",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadAssignedStacks(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadDeliveredStacks: any = createAsyncThunk(
    "app/loadDeliveredStacks",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadDeliveredStacks(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadClosedStacks: any = createAsyncThunk(
    "app/loadClosedStacks",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadClosedStacks(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadAllStacks: any = createAsyncThunk(
    "app/loadAllStacks",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadAllStacks(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadAllNotAnsweredStacks: any = createAsyncThunk(
    "app/loadAllNotAnsweredStacks",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadAllNotAnsweredStacks(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const deleteStack: any = createAsyncThunk(
    "app/deleteStack",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.deleteStack(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadStackRequests: any = createAsyncThunk(
    "app/loadStackRequests",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await letterMgmtRestService.loadRequestedStacks(finalData);
            console.log(finalData, response, callbackSuccess, callbackError)
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);



export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setAppState: (state, action) => {
            console.log("Action called setAppState")
            console.log(state)
            console.log(action.payload)
            state = {...state, ...action.payload};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLoggedInUser.pending, (state) => {
                state.isLoggeInUserLoading = true;
            })
            .addCase(getLoggedInUser.fulfilled, (state, action) => {
                state.isLoggeInUserLoading = false;
                state.loggedInUser = action.payload;
            })
            .addCase(getLoggedInUser.rejected, (state, action) => {
                state.isLoggeInUserLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(loadLetters.pending, (state) => {
                state.isLettersLoading = true;
            })
            .addCase(loadLetters.fulfilled, (state, action) => {
                state.isLettersLoading = false;
                state.letters = action.payload;
            })
            .addCase(loadLetters.rejected, (state, action) => {
                state.isLettersLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(searchLettersToPresentToHudhur.pending, (state) => {
                state.isSearchLettersToPresentToHudhurLoading = true;
            })
            .addCase(searchLettersToPresentToHudhur.fulfilled, (state, action) => {
                state.isSearchLettersToPresentToHudhurLoading = false;
                state.lettersPresentToHudhur = action.payload;
            })
            .addCase(searchLettersToPresentToHudhur.rejected, (state, action) => {
                state.isSearchLettersToPresentToHudhurLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(loadLetter.pending, (state) => {
                state.isLetterLoading = true;
            })
            .addCase(loadLetter.fulfilled, (state, action) => {
                state.isLetterLoading = false;
                state.currentLetter = action.payload;
            })
            .addCase(loadLetter.rejected, (state, action) => {
                state.isLetterLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(updateLetter.pending, (state) => {
                state.isLetterLoading = true;
            })
            .addCase(updateLetter.fulfilled, (state, action) => {
                state.isLetterLoading = false;
                state.currentLetter = action.payload;
                state.lettersByStack = state.lettersByStack != null ? state.lettersByStack.map(letter => letter.id === action.payload.id ? action.payload : letter) : null;
                
            })
            .addCase(updateLetter.rejected, (state, action) => {
                state.isLetterLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(loadLettersByStack.pending, (state) => {
                state.isLettersByStackLoading = true;
            })
            .addCase(loadLettersByStack.fulfilled, (state, action) => {
                state.isLettersByStackLoading = false;
                state.lettersByStack = action.payload;
            })
            .addCase(loadLettersByStack.rejected, (state, action) => {
                state.isLettersByStackLoading = false;
                state.lettersByStack = [];
                state.errorPaylod = action.payload;
            })
            .addCase(loadStack.pending, (state) => {
                state.isCurrentStackLoading = true;
            })
            .addCase(loadStack.fulfilled, (state, action) => {
                state.isCurrentStackLoading = false;
                state.currentStack = action.payload;
            })
            .addCase(loadStack.rejected, (state, action) => {
                state.isCurrentStackLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(createStackRequest.pending, (state) => {
                state.isCreateStackRequestLoading = true;
            })
            .addCase(createStackRequest.fulfilled, (state, action) => {
                state.isCreateStackRequestLoading = false;
                state.myStacks[action.payload.requestedStackType].push(action.payload) 
            })
            .addCase(createStackRequest.rejected, (state, action) => {
                state.isCreateStackRequestLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(createSummary.pending, (state) => {
                state.isCreateSummaryLoading = true;
            })
            .addCase(createSummary.fulfilled, (state, action) => {
                state.isCreateSummaryLoading = false;
                state.summaries.push(action.payload)
            })
            .addCase(createSummary.rejected, (state, action) => {
                state.isCreateSummaryLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(loadSummaries.pending, (state) => {
                state.isSummariesLoading = true;
            })
            .addCase(loadSummaries.fulfilled, (state, action) => {
                state.isSummariesLoading = false;
                state.summaries = action.payload;
            })
            .addCase(loadSummaries.rejected, (state, action) => {
                state.isSummariesLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(createAnswer.pending, (state) => {
                state.isCreateAnswerLoading = true;
            })
            .addCase(createAnswer.fulfilled, (state, action) => {
                state.isCreateAnswerLoading = false;
                state.answers.push(action.payload)
            })
            .addCase(createAnswer.rejected, (state, action) => {
                state.isCreateAnswerLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(loadAnswers.pending, (state) => {
                state.isAnswersLoading = true;
            })
            .addCase(loadAnswers.fulfilled, (state, action) => {
                state.isAnswersLoading = false;
                state.answers = action.payload;
            })
            .addCase(loadAnswers.rejected, (state, action) => {
                state.isAnswersLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(loadMyStacks.pending, (state) => {
                state.isMyStackRequestLoading = true;
            })
            .addCase(loadMyStacks.fulfilled, (state, action) => {
                state.isMyStackRequestLoading = false;
                state.myStacks = action.payload
            })
            .addCase(loadMyStacks.rejected, (state, action) => {
                state.isMyStackRequestLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(loadAssignedStacks.pending, (state) => {
                state.isStacksLoading = true;
            })
            .addCase(loadAssignedStacks.fulfilled, (state, action) => {
                state.isStacksLoading = false;
                state.assignedStacks = action.payload;
            })
            .addCase(loadAssignedStacks.rejected, (state, action) => {
                state.isStacksLoading = false;
                state.errorPayload = action.payload;
            })
            .addCase(loadDeliveredStacks.pending, (state) => {
                state.isStacksLoading = true;
            })
            .addCase(loadDeliveredStacks.fulfilled, (state, action) => {
                state.isStacksLoading = false;
                state.deliveredStacks = action.payload;
            })
            .addCase(loadDeliveredStacks.rejected, (state, action) => {
                state.isStacksLoading = false;
                state.errorPayload = action.payload;
            })
            .addCase(loadClosedStacks.pending, (state) => {
                state.isStacksLoading = true;
            })
            .addCase(loadClosedStacks.fulfilled, (state, action) => {
                state.isStacksLoading = false;
                state.closedStacks = action.payload;
            })
            .addCase(loadClosedStacks.rejected, (state, action) => {
                state.isStacksLoading = false;
                state.errorPayload = action.payload;
            })            
            .addCase(loadAllStacks.pending, (state) => {
                state.isStacksLoading = true;
            })
            .addCase(loadAllStacks.fulfilled, (state, action) => {
                state.isStacksLoading = false;
                state.allStacks = action.payload;
            })
            .addCase(loadAllStacks.rejected, (state, action) => {
                state.isStacksLoading = false;
                state.errorPayload = action.payload;
            })
            .addCase(loadStackRequests.pending, (state) => {
                state.isStackRequestsLoading = true;
            })
            .addCase(loadAllNotAnsweredStacks.fulfilled, (state, action) => {
                state.isStacksLoading = false;
                state.notAnsweredStacks = action.payload;
            })
            .addCase(loadAllNotAnsweredStacks.rejected, (state, action) => {
                state.isStacksLoading = false;
                state.errorPayload = action.payload;
            })
            .addCase(loadAllNotAnsweredStacks.pending, (state) => {
                state.isStackRequestsLoading = true;
            })
            .addCase(loadStackRequests.fulfilled, (state, action) => {
                state.isStackRequestsLoading = false;
                state.stackRequests = action.payload;
            })
            .addCase(loadStackRequests.rejected, (state, action) => {
                state.isStackRequestsLoading = false;
                state.errorPayload = action.payload;
            })
            .addCase(loadStaffMembers.pending, (state) => {
                state.isStaffMembersLoading = true;
            })
            .addCase(loadStaffMembers.fulfilled, (state, action) => {
                state.isStaffMembersLoading = false;
                state.staffMembers = action.payload;
            })
            .addCase(loadStaffMembers.rejected, (state, action) => {
                state.isStaffMembersLoading = false;
                state.errorPayload = action.payload;
            })
            .addCase(reassignStack.pending, (state) => {
                state.isReassignStackLoading = true;
            })
            .addCase(reassignStack.fulfilled, (state, action) => {
                state.isReassignStackLoading = false;
            })
            .addCase(reassignStack.rejected, (state, action) => {
                state.isReassignStackLoading = false;
                state.errorPayload = action.payload;
            })
            .addCase(unassignStack.pending, (state) => {
                state.isUnassignStackLoading = true;
            })
            .addCase(unassignStack.fulfilled, (state, action) => {
                state.isUnassignStackLoading = false;
            })
            .addCase(unassignStack.rejected, (state, action) => {
                state.isUnassignStackLoading = false;
                state.errorPayload = action.payload;
            })
            .addCase(deliverStack.pending, (state) => {
                state.isDeliverStackLoading = true;
            })
            .addCase(deliverStack.fulfilled, (state, action) => {
                state.isDeliverStackLoading = false;
            })
            .addCase(deliverStack.rejected, (state, action) => {
                state.isDeliverStackLoading = false;
                state.errorPayload = action.payload;
            })
            .addCase(closeStack.pending, (state) => {
                state.isCloseStackLoading = true;
            })
            .addCase(closeStack.fulfilled, (state, action) => {
                state.isCloseStackLoading = false;
            })
            .addCase(closeStack.rejected, (state, action) => {
                state.isCloseStackLoading = false;
                state.errorPayload = action.payload;
            })
            .addCase(submitStack.pending, (state) => {
                state.iSsubmitStackLoading = true;
            })
            .addCase(submitStack.fulfilled, (state, action) => {
                state.isSubmitStackLoading = false;
            })
            .addCase(submitStack.rejected, (state, action) => {
                state.isSubmitStackLoading = false;
                state.errorPayload = action.payload;
            })
            .addCase(deleteStack.pending, (state) => {
                state.isDeleteStackLoading = true;
            })
            .addCase(deleteStack.fulfilled, (state, action) => {
                state.isDeleteStackLoading = false;
            })
            .addCase(deleteStack.rejected, (state, action) => {
                state.isDeleteStackLoading = false;
                state.errorPayload = action.payload;
            })
    },
});

export const {setAppState} = appSlice.actions;

export default appSlice.reducer;