import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {loadLettersByStack, loadStack} from "../../store/app/app.slice";
import {Col, Pagination, Row, Spin} from "antd";
import StackLetter from "./StackLetter";
import {ProcessedLetter} from "ps-letter-mgmtrest-client";

export default function StackLetterDetail(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const [currentLetterSelected, setCurrentLetterSelected] = useState(null);
    const {currentStack} = useSelector((state) => state.app);
    const {lettersByStack} = useSelector((state) => state.app);
    const [currentLetterIndex, setCurrentLetterIndex] = useState(0);
    
    useEffect(() => {
        dispatch(loadStack({finalData: {id: params?.stackId}}))
        dispatch(loadLettersByStack({finalData: {stackId: params?.stackId}}))
        
    }, []);
    
    useEffect(() => {
        if(lettersByStack && lettersByStack.length > 0) {
            setCurrentLetterIndex(lettersByStack.findIndex(letter => letter.id === params?.letterId))
            setCurrentLetterSelected(lettersByStack.find(letter => letter.id === params?.letterId))
            
        }
    }, [lettersByStack, params?.letterId])

    const navigateToLetterView = (record: ProcessedLetter) => {
        history.push("/process/"+params?.stackType+"/stacks/"+params?.stackId+"/"+record.id)
    }

    const onLetterProcessed = (letter: ProcessedLetter) => {
        if(lettersByStack && lettersByStack.length > 0)
        {
            if(lettersByStack.length > currentLetterIndex+1)
            {
                history.push("/process/"+params?.stackType+"/stacks/"+params?.stackId+"/"+lettersByStack[currentLetterIndex+1].id)
            }
            else{
                history.push("/process/"+params?.stackType+"/stacks/"+params?.stackId)
            }
        }
    }
    
    return <>
        { (currentStack && currentLetterSelected && ""+currentStack.id === params.stackId && ""+currentLetterSelected.id === params.letterId) ? 
            <>
                <Row style={{height: "80%"}}>
                    <Col span={24} style={{height: "100%"}} >
                        <StackLetter key={params.letterId} letter={currentLetterSelected} stack={currentStack} onLetterProcessed={onLetterProcessed}/>
                    </Col>
                </Row>
                <Row justify={"center"}>
                    <Col span={24} flex={"none"}>
                        <div>
                        <Pagination simple current={currentLetterIndex+1} total={lettersByStack?.length} pageSize={1} onChange={(page) => {
                        setCurrentLetterIndex(page-1)
                        navigateToLetterView(lettersByStack[page-1])
                    }}/></div>
                        </Col>
                    
                </Row>
            </>
            : <Spin tip="Loading..."></Spin>}
    </>
}