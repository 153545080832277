import LetterTable from "../components/tables/LetterTables";

function LetterOverview({filtered}) {
  return (
       <>
           <LetterTable key={filtered ? "toHudur" : "allLetters"} filtered={filtered}/>
       </> 
      
  );
}

export default LetterOverview;
