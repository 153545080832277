
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  StackRequestsTable,
  AssignedStacksTable,
  DeliveredStacksTable,
  AllStacksTable
} from "../components/tables/StacksTables";
import {
  loadAssignedStacks,
  loadStackRequests,
  assignStackRequest,
  unassignStack,
  deleteStack,
  loadDeliveredStacks,
  submitStack,
  closeStack,
  downloadStackAttachment,
  createStackRequest, loadAllNotAnsweredStacks, loadAllStacks,
} from "../store/app/app.slice";
import {useHistory, useParams,} from "react-router-dom";
import {message, notification} from "antd";
import {StackRequestDto} from "ps-letter-mgmtrest-client";
import {StackDto} from "ps-letter-mgmtrest-client";
import CreateRequestStackDialog from "../components/dialogs/CreateRequestStackDialog";
import {CreateStackRequestDto} from "ps-letter-mgmtrest-client";


function StacksOverview({archive}) {

  const dispatch = useDispatch();
  const params = useParams();
  const { stackRequests, assignedStacks, deliveredStacks, allStacks, notAnsweredStacks } = useSelector((state) => state.app);
  const currentStackType = params?.stackType?.toUpperCase()
  const history = useHistory();
  const [openStackRequestDialog, setOpenStackRequestDialog] = useState(false);
  const showModal = () => {
    setOpenStackRequestDialog(true);
  };

  const hideModal = () =>  {
    setOpenStackRequestDialog(false);
  }
  
  const navigateToStackDetailsView = (record) => {

    if(currentStackType !== undefined && currentStackType !== null)
    {
      history.push("/process/"+params?.stackType+"/stacks/"+record.id)  
    }else{
      history.push("/stacks/"+record.id) // stacks/:stackid
    }
    
  }

  const fetchData = () => {
    
    if(archive === true){
      dispatch(loadAllStacks({}));  
    }else{
      dispatch(loadStackRequests({}));
      dispatch(loadAssignedStacks({}));
      dispatch(loadDeliveredStacks({}));
    }
    if(currentStackType === "answer")
    {
      dispatch(loadAllNotAnsweredStacks({}));  
    }
  };

  useEffect(() => {
    fetchData();
  }, [params?.stackType]);
  
  const handleRemoveRequest = (record) => {
    dispatch(deleteStack({
      finalData: {id: record.id},
      callbackSuccess: (response) => {
        console.log("Stack deleted successfully", response);
        fetchData();
        message.success("Stack deleted successfully")
      },
      callbackError: (error) => {
        console.error("Error deleting stack", error);
        message.error({content: error})
      }
    }))
  };
  
  const handleUnassignRequest = (record) => {
    dispatch(unassignStack({
      finalData: {stackId: record.id},
      callbackSuccess: (response) => {
        console.log("Stack unassigned successfully", response);
        fetchData();
        message.success("Stack unassigned successfully")
      },
      callbackError: (error) => {
        console.error("Error unassigning stack", error);
        message.error({content: error})
      }
    }));
  };

  const handleSubmitStack = (record) => {
    dispatch(submitStack({
      finalData: {stackId: record.id},
      callbackSuccess: (response) => {
        console.log("Stack submitted successfully", response);
        notification['success']({
          message: 'Stack submitted',
          description:
              'Stack #'+record.id+' has been submitted.',
        });
        fetchData();
      },
      callbackError: (error) => {
        console.error("Error closing stack", error);
        notification['error']({
          message: 'Error closing stack',
          description:error,
        });
      }
    }));
  };

  const handleLinkedStackRequest = (record) =>  {
    
    const stackRequest : CreateStackRequestDto = {
      requestedStackType: "ANSWER",
      requestedLanguages: record.requestedLanguages,
      requestedAmount: record.assignedAmount,
      linkedStack: record
    }
    dispatch(createStackRequest({
      finalData: {
        requestedStack: stackRequest
      },
      callbackSuccess: (response: StackDto) => {
        console.log("Stack requested successfully", response);
        
        if(response.status === "ASSIGNED")
        {
          notification['success']({
            message: 'Answer Stack assigned',
            description:
                'Stack #'+response.id+' created.',
          });  
        }else{
          notification['warn']({
            message: 'Answer Stack requested',
            description:
                'Stack Request #'+response.id+' created.',
          });
        }
        fetchData();
      },
      callbackError: (error) => {
        console.error("Error creating stack", error);
        notification['error']({
          message: 'Error creating stack',
          description:error,
        });
      }
    }));
  }
  
  const handleCloseStack = (record) => {
    dispatch(closeStack({
      finalData: {stackId: record.id},
      callbackSuccess: (response) => {
        console.log("Stack closed successfully", response);
        notification['success']({
          message: 'Stack closed',
          description:
              'Stack #'+record.id+' has been closed.',
        });
        fetchData();
      },
      callbackError: (error) => {
        console.error("Error closing stack", error);
        notification['error']({
          message: 'Error closing stack',
          description:error,
        });
      }
    }));
  };

  const handleDownloadStackAttachment = (record) => {
    dispatch(downloadStackAttachment({
      finalData: {stackId: record.id},
      callbackSuccess: (response) => {
        console.log("Download started", response);
        notification['success']({
          message: 'Download started',
          description:
              'Stack #'+record.id+' attachment download started.',
        });
      },
      callbackError: (error) => {
        console.error("Error downloading stack attachments", error);
        notification['error']({
          message: 'Error downloading stack ',
          description:error,
        });
      }
    }));
  };
  
  const handleConfirmRequest = (data) => {
    //Reshape the data and send request using dispatch
    const requestData = {
      assignedStack: {
        id: data.id,
        assigneeId: data.requesterId,
        assigneeName: data.requesterName,
        assignedAmount: data.editedAmount,
        assignedLanguages: data.requestedLanguages ? data.requestedLanguages.filter(lang => lang != null) : [],
        assignmentType: data.assignmentType,
        assignedStackType: data.requestedStackType
      }
    }
    dispatch(assignStackRequest({
      finalData: requestData,
      callbackSuccess: (response) => {
        console.log("Stack request assigned successfully", response);
        fetchData();
        message.success("Stack assigned successfully")
      },
      callbackError: (error) => {
        console.error("Error assigning stack request", error);
        message.error({content: error})
      }
    }));
    console.log("Confirm Request Handler", data);
  };
  
  if(archive)
  {
    return <div>
          <AllStacksTable
              key={currentStackType+"allStacksTable"}
              stacks={allStacks}
              navigateToStackDetailsView={navigateToStackDetailsView}
              title={"Stacks Archive"}
          /> 
    </div>
  }else{
    return (
        <div>
          {"SUMMARY" === currentStackType || undefined === currentStackType ?
              <StackRequestsTable
                  key={currentStackType+"stackRequestsTable"}
                  stackRequests={stackRequests.filter((stack: StackRequestDto) => currentStackType === undefined || currentStackType === stack.requestedStackType)}
                  handleRemoveRequest={handleRemoveRequest}
                  handleConfirmRequest={handleConfirmRequest}
                  showNewStackRequestModal={currentStackType === "SUMMARY" ? showModal : undefined}
                  showNewStackRequestTitle={currentStackType === "SUMMARY" ? "Request Summary Stack" : undefined}
                  title={currentStackType === "SUMMARY" ? "Requested Summary Stacks" : "Requested Stacks"}
                  stackType={currentStackType}
              /> : ""}

          {"ANSWER" === currentStackType ?
              <DeliveredStacksTable
                  key={currentStackType+"deliveredSummaryStacksTable"}
                  assignedStacks={notAnsweredStacks.filter((stack: StackDto) => "SUMMARY" === stack.assignedStackType && !stack.referencedStackId)}
                  navigateToStackDetailsView={navigateToStackDetailsView}
                  handleSubmitStack={handleSubmitStack}
                  handleCloseStack={handleCloseStack}
                  handleDownloadStackAttachment={handleDownloadStackAttachment}
                  fetchData={fetchData}
                  title={"Summaries ready for Answering"}
                  stackType={"SUMMARY"}
                  handleLinkedStackRequest={handleLinkedStackRequest}
              /> : ""}

          {"ANSWER" === currentStackType ?
              <StackRequestsTable
                  key={currentStackType+"stackRequestsTable"}
                  stackRequests={stackRequests.filter((stack: StackRequestDto) => "ANSWER" === stack.requestedStackType)}
                  handleRemoveRequest={handleRemoveRequest}
                  handleConfirmRequest={handleConfirmRequest}
                  title={"Requested Answer Stacks"}
                  stackType={currentStackType}
              /> : ""}

          <AssignedStacksTable
              key={currentStackType+"assignedStacksTable"}
              assignedStacks={assignedStacks.filter((stack: StackDto) => currentStackType === undefined || currentStackType === stack.assignedStackType)}
              navigateToStackDetailsView={navigateToStackDetailsView}
              handleUnassignRequest={handleUnassignRequest}
              fetchData={fetchData}
              showNewStackRequestModal={currentStackType === "FILTRATION" ? showModal : undefined}
              showNewStackRequestTitle={currentStackType === "FILTRATION" ? "Start new Filtration Stack" : undefined}
              title={currentStackType === "FILTRATION" ? "In Filtration" : currentStackType === "SUMMARY" ? "Creating Summaries" : currentStackType === "ANSWER" ? "Writing Answers" : "Assigned Stacks"}
              stackType={currentStackType}
          />

          <DeliveredStacksTable
              key={currentStackType+"deliveredStacksTable"}
              assignedStacks={deliveredStacks.filter((stack: StackDto) => currentStackType === undefined || currentStackType === stack.assignedStackType)}
              navigateToStackDetailsView={navigateToStackDetailsView}
              handleSubmitStack={handleSubmitStack}
              handleCloseStack={handleCloseStack}
              handleDownloadStackAttachment={handleDownloadStackAttachment}
              fetchData={fetchData}
              title={currentStackType === "FILTRATION" ? "Filtered Stacks" : currentStackType === "SUMMARY" ? "Printed Summaries" : currentStackType === "ANSWER" ? "Printed Answers" : "Delivered Stacks"}
              stackType={currentStackType}
          />

          <CreateRequestStackDialog showModal={openStackRequestDialog} onHideModal={hideModal}/>

        </div>

    );
  }
}

export default StacksOverview;
