import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Upload,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
    Space,
    Popconfirm,
    Select,
    Input,
    Tooltip,
    Divider, notification

} from "antd";

import {useState} from "react";
import moment from "moment/moment";
import {
    CheckCircleOutlined,
    CloseCircleOutlined, DownCircleFilled,
    DownloadOutlined,
    ExportOutlined,
    FileDoneOutlined,
    FileProtectOutlined, FileWordOutlined,
    PlusOutlined,
    RightOutlined,
    SafetyCertificateOutlined,
    SaveOutlined,
    UserDeleteOutlined,
    UserSwitchOutlined
} from "@ant-design/icons";
import CreateRequestStackDialog from "../dialogs/CreateRequestStackDialog";
import ReassignStackDialog from "../dialogs/ReassignStackDialog";
import {closeStack, submitStack} from "../../store/app/app.slice";
import {
    getLanguagesLabel, getStackStatusLabel, getStackTypeLabel,
    letterStatusFilterDropdown,
    stackStatusFilterDropdown,
    stackTypeFilterDropdown
} from "../helper/labelHelper";
import {getAuthenticatedUser} from "../../utils/authHelper";
import {StackDto} from "ps-letter-mgmtrest-client";


const {Option} = Select;
const {Title} = Typography;


export function getCategoryColor(category) {
    // Define color mapping for categories
    const colorMap = {
        Health: "#ffd700",
        Study: "#4169e1",
        Rishta: "#ff6347",
        Jamaat: "#ff0000",
        Parents: "#32cd32",
        Jamia: "#ffa500",
        Children: "#1e90ff",
        Economy: "#8a2be2",
        Testcategory: "#e5005e",
    };
    return colorMap[category] || "#000000";
}

export function getStatusColor(status) {
    // Define color mapping for statuses
    const colorMap = {
        REQUESTED: "#6f7173",
        ASSIGNED: "#52c41a",
        DELIVERED: "#4255ff",
        SUBMITTED: "#884712",
        CLOSED: "#000000",
    };
    return colorMap[status] || "#000000";
}

export function getStackTypeColor(stackType) {
    // Define color mapping for statuses
    const colorMap = {
        FILTRATION: "#5c5959",
        SUMMARY: "#5c5959",
        ANSWER: "#5c5959",
    };
    return colorMap[stackType] || "#000000";
}

function StackRequestsTable({
                                stackRequests,
                                handleRemoveRequest,
                                handleConfirmRequest,
                                showNewStackRequestModal,
                                showNewStackRequestTitle,
                                stackType,
                                title
                            }) {
    const [editingAmounts, setEditingAmounts] = useState({});
    const [assignmentTypes, setAssignmentTypes] = useState({});

    const columns = [
        {
            title: "Stack ID",
            dataIndex: "id",
            key: "id",
        },
        {
            title: "Worker Name",
            dataIndex: "requesterName",
            key: "requesterName",
        },
        {
            title: "Requested Amount",
            dataIndex: "requestedAmount",
            key: "requestedAmount",
        },
        {
            title: "Requested Language",
            dataIndex: "requestedLanguages",
            key: "requestedLanguages",
            render: (requestedLanguages) => {
                if (!requestedLanguages || !Array.isArray(requestedLanguages)) {
                    return <span>-</span>;
                }

                const nonNullLanguages = requestedLanguages.filter(lang => lang != null);

                if (nonNullLanguages.length === 0) {
                    return <span>-</span>;
                }

                return <span>{nonNullLanguages.map(lng => getLanguagesLabel(lng)).join('/')}</span>;
            },
        },
        
    ];

    if (getAuthenticatedUser().isPortalSupervisor()) {
        
        columns.push({
                title: "Edit Amount",
                key: "editAmount",
                render: (_, record) => (
                    <Space>
                        <Input
                            defaultValue={record.requestedAmount}
                            style={{width: 60, textAlign: 'center'}}
                            onChange={(e) => setEditingAmounts({...editingAmounts, [record.id]: e.target.value})}
                        />
                    </Space>
                ),
            },
            {
                title: "Assignment Type",
                key: "Assignment type",
                render: (_, record) => (
                    <Select
                        defaultValue="AUTO_ASSIGN"
                        style={{width: 120, backgroundColor: 'brown'}}
                        onChange={(value) => setAssignmentTypes({...assignmentTypes, [record.id]: value})}
                    >
                        <Option value="AUTO_ASSIGN">Auto Assign</Option>
                        {/*<Option value="PER_TOPICS">Per Topics</Option>*/}
                    </Select>
                ),
            },
            {
                title: "Accept / Remove Request",
                key: "removeAssignment",
                render: (_, record) => (
                    <Space split={<Divider type="vertical"/>}>
                        <Tooltip title="Confirm Request">
                            <Popconfirm
                                title="Are you sure you want to confirm this request?"
                                onConfirm={() => handleConfirmRequest({
                                    ...record,
                                    editedAmount: editingAmounts[record.id] || record.requestedAmount,
                                    assignmentType: assignmentTypes[record.id] || 'AUTO_ASSIGN'
                                })}
                                okText="Yes"
                                cancelText="No"
                            >
                                <CheckCircleOutlined style={{color: 'green', cursor: 'pointer', fontSize: '22px'}}/>
                            </Popconfirm>
                        </Tooltip>
                        <Tooltip title="Remove Request">
                            <Popconfirm
                                title="Are you sure you want to remove this request?"
                                onConfirm={() => handleRemoveRequest(record)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <CloseCircleOutlined style={{color: 'red', cursor: 'pointer', fontSize: '22px'}}/>
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                ),
            })
        
        
    } else {
        columns.push(
            {
                title: "Next Step",
                key: "stackAction",
                render: (_, record) => (
                    <Button className={"ant-tag-orange"} type="default">
                        Waiting for Supervisor
                    </Button>
                ),
            },
            {
                title: "Cancel Request",
                key: "cancelAssignment",
                render: (_, record) => (
                    <Space>
                        <Tooltip title="Remove Request">
                            <Popconfirm
                                title="Are you sure you want to cancel this request?"
                                onConfirm={() => handleRemoveRequest(record)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <CloseCircleOutlined style={{color: 'red', cursor: 'pointer', fontSize: '22px'}}/>
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                ),
            })
    }

    return (
        <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={title}
            extra={showNewStackRequestModal ?
                <Button type="primary" onClick={showNewStackRequestModal}><PlusOutlined/>{showNewStackRequestTitle}
                </Button> : ""}
        >
            <div className="table-responsive">
                <Table
                    columns={columns}
                    dataSource={stackRequests}
                    pagination={true}
                    className="ant-border-space"
                    onRow={(record, rowIndex) => {
                        return {
                            //onClick: event => {navigateToStackDetailsView(record)}, // click row
                            onClick: event => {
                            }, // click row
                            onDoubleClick: event => {
                            }, // double click row
                            onContextMenu: event => {
                            }, // right button click row
                            onMouseEnter: event => {
                            }, // mouse enter row
                            onMouseLeave: event => {
                            }, // mouse leave row
                        };
                    }}
                />
            </div>
        </Card>
    );
}

function AssignedStacksTable({
                                 assignedStacks,
                                 navigateToStackDetailsView,
                                 handleUnassignRequest,
                                 fetchData,
                                 showNewStackRequestModal,
                                 showNewStackRequestTitle,
                                 stackType,
                                 title
                             }) {
    const [openReassignStackDialog, setOpenReassignStackDialog] = useState(false);
    const [currentStackId, setCurrentStackId] = useState(null);

    const showModal = (stackId) => {
        setCurrentStackId(stackId);
        setOpenReassignStackDialog(true);
    };

    const hideModal = () => {
        setCurrentStackId(null);
        setOpenReassignStackDialog(false);
    }

    const columns = [
        {
            title: "Stack ID",
            dataIndex: "id",
            key: "id",
            render: (id, record) => {
                return (
                    <a onClick={() => navigateToStackDetailsView(record)}>
                        {record.id}
                    </a>
                );
            }
        },
        {
            title: "Worker Name",
            dataIndex: "assigneeName",
            key: "assigneeName",
        },
        {
            title: "Assigned Amount",
            dataIndex: "assignedAmount",
            key: "assignedAmount",
        },
        {
            title: "Assigned Languages",
            dataIndex: "assignedLanguages",
            key: "assignedLanguages",
            render: (assignedLanguages) => {
                if (!assignedLanguages || !Array.isArray(assignedLanguages)) {
                    return <span>-</span>;
                }

                const nonNullLanguages = assignedLanguages.filter(lang => lang != null);

                if (nonNullLanguages.length === 0) {
                    return <span>-</span>;
                }

                return <span>{nonNullLanguages.map(lng => getLanguagesLabel(lng)).join('/')}</span>;
            },
        },
        {
            title: "Last Update",
            dataIndex: "modified",
            key: "modified",
            render: (date) => (date ? moment(date).format("DD-MM-YYYY / hh:mm A") : "N/A"),
        }
    ];

    if (getAuthenticatedUser().isPortalSupervisor()) {
        columns.push(
            {
                title: "Reassign / Unassign",
                key: "reAndUnAssignment",
                render: (_, record) => (
                    <Space split={<Divider type="vertical"/>}>

                        <UserSwitchOutlined style={{color: 'green', cursor: 'pointer', fontSize: '22px'}}
                                            onClick={() => showModal(record.id)}/>
                        <Tooltip title="Unassign Stacke">
                            <Popconfirm
                                title="Are you sure you want to unassing this Stack? All linked letters will be unassigned."
                                onConfirm={() => handleUnassignRequest(record)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <UserDeleteOutlined style={{color: 'red', cursor: 'pointer', fontSize: '22px'}}/>
                            </Popconfirm>
                        </Tooltip>
                    </Space>
                ),
            }
        )
    } else {
        columns.push(
            {
                title: "Action",
                key: "stackAction",
                render: (_, record: StackDto) => (
                    <Button className={"ant-tag-orange"} type="default" onClick={() => {
                        navigateToStackDetailsView(record)
                    }}>
                        {record.assignedStackType === "FILTRATION" ? "Do Filtration" : record.assignedStackType === "SUMMARY" ? "Do Summary" : "Write Answers"}
                    </Button>
                ),
            }
        )
    }

    return (
        <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={title}
            extra={showNewStackRequestModal ?
                <Button type="primary" onClick={showNewStackRequestModal}><PlusOutlined/>{showNewStackRequestTitle}
                </Button> : ""}
        >
            <div className="table-responsive">
                <Table
                    columns={columns}
                    dataSource={assignedStacks}
                    pagination={true}
                    className="ant-border-space"
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => {
                            }, // click row
                            onDoubleClick: event => {
                                navigateToStackDetailsView(record)
                            }, // double click row
                            onContextMenu: event => {
                            }, // right button click row
                            onMouseEnter: event => {
                            }, // mouse enter row
                            onMouseLeave: event => {
                            }, // mouse leave row
                        };
                    }}
                />
            </div>
            <ReassignStackDialog showModal={openReassignStackDialog} onHideModal={hideModal} stackId={currentStackId}
                                 fetchData={fetchData}/>
        </Card>
    );
}

function DeliveredStacksTable({
                                  assignedStacks,
                                  navigateToStackDetailsView,
                                  handleSubmitStack,
                                  handleCloseStack,
                                  handleDownloadStackAttachment,
                                  fetchData,
                                  title,
                                  showNewStackRequestModal,
                                  showNewStackRequestTitle,
                                  stackType,
                                  handleLinkedStackRequest
                              }) {
    const [openReassignStackDialog, setOpenReassignStackDialog] = useState(false);
    const [currentStackId, setCurrentStackId] = useState(null);

    const showModal = (stackId) => {
        setCurrentStackId(stackId);
        setOpenReassignStackDialog(true);
    };

    const hideModal = () => {
        setCurrentStackId(null);
        setOpenReassignStackDialog(false);
    }

    const columns = [
        {
            title: "Stack ID",
            dataIndex: "id",
            key: "id",
            render: (id, record) => {
                return (
                    <a onClick={() => navigateToStackDetailsView(record)}>
                        {record.id}
                    </a>
                );
            }
        },
        {
            title: "Worker Name",
            dataIndex: "assigneeName",
            key: "assigneeName",
        },
        {
            title: "Assigned Amount",
            dataIndex: "assignedAmount",
            key: "assignedAmount",
        },
        {
            title: "Assigned Languages",
            dataIndex: "assignedLanguages",
            key: "assignedLanguages",
            render: (assignedLanguages) => {
                if (!assignedLanguages || !Array.isArray(assignedLanguages)) {
                    return <span>-</span>;
                }

                const nonNullLanguages = assignedLanguages.filter(lang => lang != null);

                if (nonNullLanguages.length === 0) {
                    return <span>-</span>;
                }

                return <span>{nonNullLanguages.map(lng => getLanguagesLabel(lng)).join('/')}</span>;
            },
        },
        {
            title: "Last Update",
            dataIndex: "modified",
            key: "modified",
            render: (date) => (date ? moment(date).format("DD-MM-YYYY / hh:mm A") : "N/A"),
        }
    ];

    switch (stackType) {
        case "FILTRATION":
            break;
        case "SUMMARY":
            columns.push(
                {
                    title: <><DownloadOutlined/> Attachments</>,
                    key: "stackAction",
                    render: (_, record) => (
                        <Button className={"ant-tag-green"} type="default" onClick={() => handleDownloadStackAttachment(record)}>
                            <FileWordOutlined/>Summary
                        </Button>
                    ),
                }
            )
            columns.push(
                {
                    title: "Next Step",
                    key: "stackAction",
                    render: (_, record) => (
                        record.status === "SUBMITTED" ?
                        <Button className={"ant-tag-blue"} type="default" onClick={() => handleCloseStack(record)}>
                            <SafetyCertificateOutlined /> Summary viewed by Huzoor<sup>aa</sup>
                        </Button> : record.status === "CLOSED" ?
                        <Button className={"ant-tag-blue"} type="default" onClick={() => handleLinkedStackRequest(record)}>
                            <SafetyCertificateOutlined /> Create Answering Stack
                        </Button> : ""
                    ),
                }
            )
            break;
        case "ANSWER":
            columns.push(
                {
                    title: <><DownloadOutlined/> Attachments</>,
                    key: "stackAction",
                    render: (_, record) => (
                        <Button className={"ant-tag-green"} type="default" onClick={() => handleDownloadStackAttachment(record)}>
                            <FileWordOutlined/>Answers
                        </Button>
                    ),
                }
            )
            columns.push(
                {
                    title: "Next Step",
                    key: "stackAction",
                    render: (_, record) => (
                        <Button className={"ant-tag-blue"} type="default" onClick={() => handleCloseStack(record)}>
                            <SafetyCertificateOutlined /> Archive Stack
                        </Button>
                    ),
                }
            )
            break;
        default:

            break;
    }

    if (getAuthenticatedUser().isPortalSupervisor()) {
        switch (stackType) {
            case "FILTRATION":
                columns.push(
                    {
                        title: "Review",
                        key: "reviewFiltrationAction",
                        render: (_, record) => (
                            <Space direction={"horizontal"} align={"center"}>
                                <FileDoneOutlined style={{color: 'orange', cursor: 'pointer', fontSize: '22px'}}
                                                  onClick={() => navigateToStackDetailsView(record)}/>
                            </Space>
                        ),
                    }
                )
                columns.push(
                    {
                        title: "Approve",
                        key: "approveFiltrationAction",
                        render: (_, record) => (
                            <Space direction={"horizontal"} align={"center"}>
                                <SafetyCertificateOutlined style={{color: 'green', cursor: 'pointer', fontSize: '22px'}}
                                                           onClick={() => handleCloseStack(record)}/>
                            </Space>
                        ),
                    }
                )
                break;
            case "SUMMARY":
                break;
            case "ANSWER":
                break;
            default:
                columns.push(
                    {
                        title: "Submit | Download | Close",
                        key: "actions",
                        render: (_, record) => (
                            <Space split={<Divider type="vertical"/>}>
                                {record.status === "DELIVERED" && (record.assignedStackType === "SUMMARY" || record.assignedStackType === "ANSWER") ?
                                    <SaveOutlined style={{color: 'green', cursor: 'pointer', fontSize: '22px'}}
                                                  onClick={() => handleSubmitStack(record)}/> :
                                    <SaveOutlined style={{color: 'lightgray', cursor: 'pointer', fontSize: '22px'}}/>}
                                {record.status === "SUBMITTED" && (record.assignedStackType === "SUMMARY" || record.assignedStackType === "ANSWER") ?
                                    <DownloadOutlined style={{color: 'green', cursor: 'pointer', fontSize: '22px'}}
                                                      onClick={() => handleDownloadStackAttachment(record)}/> :
                                    <DownloadOutlined
                                        style={{color: 'lightgray', cursor: 'pointer', fontSize: '22px'}}/>}
                                {(record.status === "DELIVERED" && record.assignedStackType === "FILTRATION") || record.status === "SUBMITTED" ?
                                    <CheckCircleOutlined style={{color: 'green', cursor: 'pointer', fontSize: '22px'}}
                                                         onClick={() => handleCloseStack(record)}/> :
                                    <CheckCircleOutlined
                                        style={{color: 'lightgray', cursor: 'pointer', fontSize: '22px'}}/>}
                            </Space>
                        ),
                    })
                break;
        }
    } else {
        switch (stackType) {
            case "FILTRATION":
                columns.push(
                    {
                        title: "Next Step",
                        key: "stackAction",
                        render: (_, record) => (
                            <Button className={"ant-tag-orange"} type="default">
                                Waiting for Supervisor
                            </Button>
                        ),
                    }
                )
                break;
            case "SUMMARY":
                break;
            case "ANSWER":
                break;
            default:

                break;
        }
    }

    return (
        <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={title}
            extra={showNewStackRequestModal ?
                <Button type="primary" onClick={showNewStackRequestModal}><PlusOutlined/>{showNewStackRequestTitle}
                </Button> : ""}
        >
            <div className="table-responsive">
                <Table
                    columns={columns}
                    dataSource={assignedStacks}
                    pagination={true}
                    className="ant-border-space"
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => {
                            }, // click row
                            onDoubleClick: event => {
                                navigateToStackDetailsView(record)
                            }, // double click row
                            onContextMenu: event => {
                            }, // right button click row
                            onMouseEnter: event => {
                            }, // mouse enter row
                            onMouseLeave: event => {
                            }, // mouse leave row
                        };
                    }}
                />
            </div>
            <ReassignStackDialog showModal={openReassignStackDialog} onHideModal={hideModal} stackId={currentStackId}
                                 fetchData={fetchData}/>
        </Card>
    );
}

function AllStacksTable({
                            stacks,
                            navigateToStackDetailsView,
                            title
                        }) {
    
    const columns = [
        {
            title: "Stack ID",
            dataIndex: "id",
            key: "id",
            sorter: (a, b) => a.id > b.id ? 1 : -1,
            render: (id, record) => {
                return (
                    <a onClick={() => navigateToStackDetailsView(record)}>
                        {record.id}
                    </a>
                );
            }
        },
        {
            title: "Worker Name",
            dataIndex: "assigneeName",
            sorter: (a, b) => a.assigneeName?.localeCompare(b.assigneeName),
            key: "assigneeName",
        },
        {
            title: "StackType",
            dataIndex: "assignedStackType",
            filters: stackTypeFilterDropdown,
            onFilter: (value, record) => record.assignedStackType.includes(value),
            sorter: (a, b) => a.assignedStackType?.localeCompare(b.assignedStackType),
            key: "assignedStackType",
            render: (assignedStackType) => (
                <Button type="text" style={{ backgroundColor: getStackTypeColor(assignedStackType), color: 'white' }}>
                    {getStackTypeLabel(assignedStackType)}
                </Button>
            ),
            
        },
        {
            title: "Assigned Amount",
            dataIndex: "assignedAmount",
            key: "assignedAmount",
            sorter: (a, b) => a.assignedAmount > b.assignedAmount ? 1 : -1,
        },
        {
            title: "Assigned Languages",
            dataIndex: "assignedLanguages",
            key: "assignedLanguages",
            sorter: (a, b) => a.assignedLanguages[0]?.localeCompare(b.assignedLanguages[0]),
            render: (assignedLanguages) => {
                if (!assignedLanguages || !Array.isArray(assignedLanguages)) {
                    return <span>-</span>;
                }

                const nonNullLanguages = assignedLanguages.filter(lang => lang != null);

                if (nonNullLanguages.length === 0) {
                    return <span>-</span>;
                }

                return <span>{nonNullLanguages.map(lng => getLanguagesLabel(lng)).join('/')}</span>;
            },
        },
        {
            title: "Last Update",
            dataIndex: "modified",
            key: "modified",
            defaultSortOrder: "descend",
            sorter: (a, b) => a.modified?.localeCompare(b.modified),
            render: (date) => (date ? moment(date).format("DD-MM-YYYY / hh:mm A") : "N/A"),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            filters: stackStatusFilterDropdown,
            defaultFilteredValue : ['CLOSED'],
            onFilter: (value, record) => record.status.includes(value),
            sorter: (a, b) => a.status?.localeCompare(b.status),
            render: (status) => (
                <Button type="text" style={{ backgroundColor: getStatusColor(status), color: 'white' }}>
                    {getStackStatusLabel(status)}
                </Button>
            ),
        },
    ];
    

    return (
        <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={title}
        >
            <div className="table-responsive">
                <Table
                    columns={columns}
                    dataSource={stacks}
                    pagination={true}
                    className="ant-border-space"
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => {
                            }, // click row
                            onDoubleClick: event => {
                                navigateToStackDetailsView(record)
                            }, // double click row
                            onContextMenu: event => {
                            }, // right button click row
                            onMouseEnter: event => {
                            }, // mouse enter row
                            onMouseLeave: event => {
                            }, // mouse leave row
                        };
                    }}
                />
            </div>
        </Card>
    );
}

// Export the new components
export {StackRequestsTable, AssignedStacksTable, DeliveredStacksTable, AllStacksTable};