import {Avatar, Button, Card, notification, Space, Table, Tag, Typography} from "antd";
import {getCategoryColor} from "./StacksTables";
import {UserDataDtoGenderEnum} from "acc-rest-client";
import {calculate_age} from "./LetterTables";
import {
    DownloadOutlined,
    FileProtectOutlined,
    LeftCircleOutlined,
    SafetyCertificateOutlined, SafetyOutlined,
} from "@ant-design/icons";
import {useHistory, useParams} from "react-router-dom";
import {
    closeStack,
    deliverStack,
    downloadAttachment,
    downloadStackAttachment,
    loadMyStacks,
    submitStack
} from "../../store/app/app.slice";
import {useDispatch} from "react-redux";
import {StackDto} from "ps-letter-mgmtrest-client";
import {
    getLanguagesLabel,
    getProcessedLetterStatusColor,
    getProcessedLetterStatusLabel,
    getTopicsLabel, letterLanguageFilterDropdown,
    letterStatusFilterDropdown
} from "../helper/labelHelper";
import moment from "moment/moment";
import gender_male2_icon from "../../assets/images/gender_male2_icon.png";
import gender_female_icon from "../../assets/images/gender_female_icon.png";
import gender_unknown_icon from "../../assets/images/gender_unknown_icon.png";

const { Title } = Typography;
export default function StackDetailTable({ stack, letters, showNewStackRequestModal, inProcess, refreshStack} : { stack: StackDto, letters: ProcessedLetter[], showNewStackRequestModal: boolean, inProcess: boolean, refreshStack: any }) {
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    
    const navigateToLetterView = (record: ProcessedLetter) => {
        let processPathPrefix= "/process/"+params?.stackType;
        
        if(inProcess)
        {
            history.push(processPathPrefix+"/stacks/"+params?.stackId+"/"+record.id)
        }else{
            history.push("/stacks/"+params?.stackId+"/"+record.id)
        }
    }
    
    const navigateToStacksView = () => {
        let processPathPrefix= "/process/"+params?.stackType;

        if(inProcess)
        {
            dispatch(loadMyStacks({}));
            history.push(processPathPrefix+"/stacks")
        }else{
            history.push("/stacks")
        }
    }
    
    const columnsLetters = [
        {
            title: "Name",
            dataIndex: "originalLetter",
            sorter: (a, b) => a.originalLetter.sender.firstName.localeCompare(b.originalLetter.sender.firstName),
            render: (letter, record) => (letter ?

                <a onClick={() => navigateToLetterView(record)}>
                    <Avatar.Group>
                        <div style={{marginRight: "3px"}}>
                            <Avatar
                                src={letter.sender.gender ? letter.sender.gender === UserDataDtoGenderEnum.Male ? gender_male2_icon : letter.sender.gender === UserDataDtoGenderEnum.Female ? gender_female_icon : gender_unknown_icon : gender_unknown_icon}
                                shape="square" size="large">{letter.sender.gender}</Avatar>
                        </div>
                        <div className="avatar-info">
                            <Title
                                level={5}>{letter.sender.firstName + " " + letter.sender.lastName}</Title>
                            <p>{letter.sender.email}</p>
                        </div>
                    </Avatar.Group>
                </a> : "N/A"),
        },
        {
            title: "Country",
            dataIndex: ["originalLetter" ,"sender","country", "defaultLabel" ],
            key: "country",
        sorter: (a, b) => a.originalLetter.sender.country.defaultLabel.localeCompare(b.originalLetter.sender.country.defaultLabel),
        },
        {
            title: "Jamaat",
        dataIndex: ["originalLetter", "sender", "jamaat"],
        sorter: (a, b) => a.originalLetter.sender.jamaat.localeCompare(b.originalLetter.sender.jamaat),
            key: "jamaat",
        },
        {
            title: "Topic",
            dataIndex: ["originalLetter", "category"],
            key: "category",
        sorter: (a, b) => a.originalLetter.category.localeCompare(b.originalLetter.category),
            render: (category) => (
                <Button type="text" style={{color: getCategoryColor(category),borderColor:getCategoryColor(category) ,borderStyle: 'solid'}}>
                    {getTopicsLabel(category)}
                </Button>
            ),
        },
        {
            title: "Age",
            dataIndex: ["originalLetter", "sender","birthdate" ],
        sorter: (a, b) => a.originalLetter.sender.birthdate.localeCompare(b.originalLetter.sender.birthdate),
            render: (birthdate) => (calculate_age(birthdate)),
        },
        {
            title: "Language",
            dataIndex: ["originalLetter", "language"],
            filters: letterLanguageFilterDropdown,
            onFilter: (value, record) => record.originalLetter.language.includes(value),
            sorter: (a, b) => a.originalLetter.language.localeCompare(b.originalLetter.language),
            render: (language) => (getLanguagesLabel(language)),
        },
        {
            title: "Content",
            dataIndex: ["originalLetter", "content"],
            key: "content",
        sorter: (a, b) => a.originalLetter.content.localeCompare(b.originalLetter.content),
            render: (content) => (content.substring(0, 20) + " ...")
        },
        {
            title: "Huzoor",
            dataIndex: ["presentToHudhur"],
            key: "hudhur",
            filters: [
                {
                    text: 'Present to Huzoor',
                    value: true,
                },
                {
                    text: 'Summarize',
                    value: false,
                },
                {
                    text: 'Not defined',
                    value: null,
                },
            ],
            onFilter: (value, record) => record.presentToHudhur === value,
            sorter: (a, b) => a.presentToHudhur - b.presentToHudhur,
            render: (presentToHudhur) => (
                <div style={{
                    backgroundColor: '#f0f0f0',
                    color: presentToHudhur ? 'green' : presentToHudhur === false ? 'red' : 'grey',
                    borderRadius: '30%',
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '12px',
                    fontWeight: 'bold'
                }}>
                    {presentToHudhur ? '✓' : presentToHudhur === false ? '❌' : ''}
                </div>
            ),
        },
        {
            title: "Attachments",
            key: "attachmentIdAnswer",
            dataIndex: ["attachmentIdAnswer"],
            render: (attachmentIdAnswer, record) => (
                <>

                    {record.attachmentIdLetterToHudhur ? <a><Tag color="green" onClick={() => handleDownloadAttachment(record.attachmentIdLetterToHudhur)} icon={<DownloadOutlined/>}>Letter</Tag></a>: ""}

                    {record.attachmentIdSummary ?  <a><Tag color="red" onClick={() => handleDownloadAttachment(record.attachmentIdSummary)} icon={<DownloadOutlined/>}>Summary</Tag></a> : ""}

                    {record.attachmentIdAnswer ? <><br/><a><Tag onClick={() => handleDownloadAttachment(record.attachmentIdAnswer)} color="blue" icon={<DownloadOutlined/>}>Answer</Tag></a></> : ""}
                </>
            ),
        },
        {
            title: "Status",
            key: "status",
            dataIndex: ["internalStatus"],
            filters: letterStatusFilterDropdown,
            onFilter: (value, record) => record.internalStatus.includes(value),
            sorter: (a, b) => a.internalStatus.localeCompare(b.internalStatus),
                render: (status, record) => (
                    <Button type="text" style={{ backgroundColor: getProcessedLetterStatusColor(record),color:'white' }}>
                        {getProcessedLetterStatusLabel(record)}
                    </Button>
                ),
        },
        {
        title: "Date",
        dataIndex: ["originalLetter", "metaData", "created"],
        key: "date",
        defaultSortOrder: "ascend",
        render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "N/A"),
        sorter: (a, b) => a.originalLetter.metaData.created.localeCompare(b.originalLetter.metaData.created),
    },
    {
            title: "By",
            dataIndex: ["assignedTo"],
            key: "by",
        },
    ];

    const handleDeliverStack = () => {
        dispatch(deliverStack({
            finalData: {stackId: params?.stackId},
            callbackSuccess: (response) => {
                console.log("Stack delivered successfully", response);
                notification['success']({
                    message: 'Stack delivered',
                    description:
                        'Stack #'+stack.id+' has been transmitted to supervisor.',
                });
                if(stack.assignedStackType === "SUMMARY" || stack.assignedStackType === "ANSWER")
                {
                    refreshStack();
                    handleDownloadStackAttachment();
                }
                else{
                    navigateToStacksView();    
                }
                
            },
            callbackError: (error) => {
                console.error("Error delivering stack", error);
                notification['error']({
                    message: 'Error delivering stack',
                    description:error,
                });
            }
        }));
    };

    const handleSubmitStack = () => {
        dispatch(submitStack({
            finalData: {stackId: params?.stackId},
            callbackSuccess: (response) => {
                console.log("Stack submitted successfully", response);
                notification['success']({
                    message: 'Stack submitted',
                    description:
                        'Stack #'+stack.id+' has been submitted.',
                });
                refreshStack();
                handleDownloadStackAttachment();
            },
            callbackError: (error) => {
                console.error("Error closing stack", error);
                notification['error']({
                    message: 'Error closing stack',
                    description:error,
                });
            }
        }));
    };

    const handleDownloadStackAttachment = () => {
        dispatch(downloadStackAttachment({
            finalData: {stackId: params?.stackId},
            callbackSuccess: (response) => {
                console.log("Download started", response);
                notification['success']({
                    message: 'Download started',
                    description:
                        'Stack #'+stack.id+' attachment download started.',
                });
            },
            callbackError: (error) => {
                console.error("Error downloading stack attachments", error);
                notification['error']({
                    message: 'Error downloading stack ',
                    description:error,
                });
            }
        }));
    };

    const handleDownloadAttachment = (attachmentId) => {
        dispatch(downloadAttachment({
            finalData: {id: attachmentId},
            callbackSuccess: (response) => {
                console.log("Download started", response);
                notification['success']({
                    message: 'Download started',
                    description:
                        'Download for Attachment with '+attachmentId+' started.',
                });
            },
            callbackError: (error) => {
                console.error("Error downloading attachment", error);
                notification['error']({
                    message: 'Error downloading attachment ',
                    description:error,
                });
            }
        }));
    };

    const handleCloseStack = () => {
        dispatch(closeStack({
            finalData: {stackId: params?.stackId},
            callbackSuccess: (response) => {
                console.log("Stack closed successfully", response);
                notification['success']({
                    message: 'Stack closed',
                    description:
                        'Stack #'+stack.id+' has been closed.',
                });
                navigateToStacksView();
            },
            callbackError: (error) => {
                console.error("Error closing stack", error);
                notification['error']({
                    message: 'Error closing stack',
                    description:error,
                });
            }
        }));
    };
    
    return (
        <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title={"Stack #"+stack.id}
            extra={<Space>
                <Button className={"ant-tag-close-icon"} type="default" onClick={() => {window.history.back()}}>
                    <LeftCircleOutlined/>Back
                </Button>
                {
                    stack.status === "ASSIGNED" ?
                        (stack.assignedStackType === "FILTRATION" ? <Button className={"ant-tag-green"} type="default" onClick={handleDeliverStack}><SafetyOutlined /> Submit Filtration</Button> :
                            stack.assignedStackType === "SUMMARY" ? <Button className={"ant-tag-green"} type="default" onClick={handleDeliverStack}><SafetyOutlined /> Submit & Print Summaries</Button>:
                                stack.assignedStackType === "ANSWER" ? <Button className={"ant-tag-green"} type="default" onClick={handleDeliverStack}><SafetyOutlined /> Submit & Print Answers</Button>: 
                                    "") 
                        : ""}
                {
                    (stack.status === "DELIVERED" || stack.status === "SUBMITTED")?
                        (stack.assignedStackType === "FILTRATION" ? <Button className={"ant-tag-blue"} type="default" onClick={handleCloseStack}><SafetyCertificateOutlined /> Approve Filtration</Button> :
                            stack.assignedStackType === "SUMMARY" ? <>
                                                                        <Button className={"ant-tag-green"} type="default" onClick={handleDownloadStackAttachment}><DownloadOutlined /> Download Attachments</Button>
                                                                        <Button className={"ant-tag-blue"} type="default" onClick={handleCloseStack}><FileProtectOutlined />Summary viewed by Huzoor<sup>aa</sup></Button>
                                                                    </>:
                                stack.assignedStackType === "ANSWER" ? <>
                                                                            <Button className={"ant-tag-green"} type="default" onClick={handleDownloadStackAttachment}><DownloadOutlined /> Download Attachments</Button>
                                                                            <Button className={"ant-tag-blue"} type="default" onClick={handleCloseStack}><FileProtectOutlined />Archive Stack</Button>
                                                                        </> : 
                                    "") 
                        : ""}
                
            </Space>}
        >
            <div className="table-responsive">
                <Table
                    columns={columnsLetters}
                    dataSource={letters} // Wrap in array as it's a single object
                    pagination={false}
                    className="ant-border-space"
                    onRow={(record, rowIndex) => {
                        return {
                            onDoubleClick: event => {navigateToLetterView(record)}, // click row
                            onClick: event => {}, // click row
                            onContextMenu: event => {}, // right button click row
                            onMouseEnter: event => {}, // mouse enter row
                            onMouseLeave: event => {}, // mouse leave row
                        };
                    }}
                />
            </div>
        </Card>
    );
}