import {letterMgmtRestClient} from "../index";

const loadLetters = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getAllLetters();
  return response.data;
};

const searchLetters = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().searchLetters(data.search);
  return response.data;
};

const loadLetter = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getLetter(data.id);
  return response.data;
};

const updateLetter = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().updateLetter(data.id, data.updatedLetter);
  return response.data;
};

const loadLettersByStack = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getAllLettersFromStacks(data.stackId);
  return response.data;
};


const loadRequestedStacks = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getAllStackRequests();
  return response.data;
};

const loadAllStacks = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getAllStacks(["REQUESTED", "ASSIGNED","DELIVERED", "SUBMITTED", "CLOSED"]);
  return response.data;
};

const loadAssignedStacks = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getAllStacks(["ASSIGNED"]);
  return response.data;
};

const loadDeliveredStacks = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getAllStacks(["DELIVERED", "SUBMITTED"]);
  return response.data;
};

const loadClosedStacks = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getAllStacks(["CLOSED"]);
  return response.data;
};

const loadAllNotAnsweredStacks = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getAllStacksNotAnswered();
  return response.data;
};

const loadStaffMembers = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getAllStaffMember();
  return response.data;
};

const loadMyStacks = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getAllMyStacks();
  return response.data;
};

const createStackRequest = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().createStackRequest(data.requestedStack);
  return response.data;
};

const assignStack = async (data) => {
  console.log("DATA IN", data)
  const response = await letterMgmtRestClient.letterMgmtApi().assignStack(data.assignedStack);
  return response.data;
};

const deliverStack = async (data) => {
  console.log("DATA IN", data)
  const response = await letterMgmtRestClient.letterMgmtApi().deliverStack(data.stackId);
  return response.data;
};

const submitStack = async (data) => {
  console.log("DATA IN", data)
  const response = await letterMgmtRestClient.letterMgmtApi().submitStack(data.stackId);
  return response.data;
};

const closeStack = async (data) => {
  console.log("DATA IN", data)
  const response = await letterMgmtRestClient.letterMgmtApi().closeStack(data.stackId);
  return response.data;
};

const unassignStack = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().unassignStack(data.stackId);
  return response.data;
};

const reassignStack = async (data) => {
  console.log("DATA IN", data)
  const response = await letterMgmtRestClient.letterMgmtApi().reassignStack(data.stackId, data.staffMember);
  return response.data;
};

const getStackRequest = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getStackRequestById(data.id);
  return response.data;
};

const getStack = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getStackById(data.id);
  return response.data;
};

const deleteStack = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().deleteStackById(data.id);
  return response.data;
};

const loadSummaries = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getAllSummaries();
  return response.data;
};

const searchSummaries = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().searchSummaries(data.search);
  return response.data;
};

const createSummary = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().createSummary(data.summary);
  return response.data;
};

const loadAnswers = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().getAllAnswers();
  return response.data;
};

const searchAnswers = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().searchAnswers(data.search);
  return response.data;
};

const createAnswer = async (data) => {
  const response = await letterMgmtRestClient.letterMgmtApi().createAnswer(data.answer);
  return response.data;
};

const downloadAttachment = async (data) => {
  return await letterMgmtRestClient.attachmentApi().downloadAttachment(data.id, {responseType: 'arraybuffer'});
};

const downloadStackAttachment = async (data) => {
  return await letterMgmtRestClient.attachmentApi().downloadForStack(data.stackId, {responseType: 'arraybuffer'});
};

const downloadForLetterForHudhur = async (data) => {
  return await letterMgmtRestClient.attachmentApi().downloadForLetterForHudhur( {responseType: 'arraybuffer'});
};

const letterMgmtRestService = {
  loadLetters,
  searchLetters,
  loadRequestedStacks,
  loadClosedStacks,
  loadAllNotAnsweredStacks,
  loadAssignedStacks,
  loadDeliveredStacks,
  loadAllStacks,
  updateLetter,
  assignStack,
  reassignStack,
  unassignStack,
  deliverStack,
  submitStack,
  downloadStackAttachment,
  downloadAttachment,
  downloadForLetterForHudhur,
  closeStack,
  loadStaffMembers,
  createStackRequest,
  getStack,
  getStackRequest,
  deleteStack,
  loadMyStacks,
  loadLettersByStack,
  loadLetter,
  loadSummaries,
  searchSummaries,
  createSummary,
  loadAnswers,
  searchAnswers,
  createAnswer
};

export default letterMgmtRestService;