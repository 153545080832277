
import {
    Row,
    Col,
    Card,
    Radio,
    Table,
    Upload,
    message,
    Progress,
    Button,
    Avatar,
    Typography,
  } from "antd";
  
  import { ToTopOutlined } from "@ant-design/icons";
  import { Link } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {UserDataDto, UserDataDtoGenderEnum} from "acc-rest-client";
import {useEffect, useState} from "react";
import {loadLetters} from "../../store/app/app.slice";
import moment from "moment/moment";
  
  
  
  const { Title } = Typography;
  
  const formProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  // table code start
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Jamaat",
      dataIndex: "jamaat",
      key: "jamaat",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (category) => (
        <Button type="text" style={{color: getCategoryColor(category),borderColor:getCategoryColor(category) ,borderStyle: 'solid'}}>
          {category}
        </Button>
      ),
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "Huzoor",
      dataIndex: "hudhur",
      key: "hudhur",
      
        render: (hudhur) => (
          <div style={{
            backgroundColor: '#f0f0f0',
            color: 'grey',
            borderRadius: '30%',
            width: '24px',
            height: '24px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '12px',
            fontWeight: 'bold'
          }}>
            {hudhur}
          </div>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <Button type="text" style={{ backgroundColor: getStatusColor(status),color:'white' }}>
          {status}
        </Button>
      ),
    },
    {
      title: "By",
      dataIndex: "by",
      key: "by",
    },
  ];

const calculate_age = (dob1) => {
  var today = new Date();
  var birthDate = moment.utc(dob1);  // create a date object directly from `dob1` argument
  //var birthDate = moment.utc(dob1);  // create a date object directly from `dob1` argument
  var age_now = today.getFullYear() - birthDate.year();
  // var m = today.getMonth() - birthDate.month();
  // if (m < 0 || (m === 0 && today.getDate() < birthDate.date()))
  // {
  //   age_now--;
  // }
  console.log(age_now);
  return age_now;
}
  
const columnsLetters = [
  {
    title: "Name",
    dataIndex: "",
    //sorter: (a, b) => a.projectType?.translations?.en?.localeCompare(b.projectType?.translations?.en),
    render: (letter) => (letter ?

        <Avatar.Group>
          <div style={{marginRight: "3px"}}>
            <Avatar
                src={letter.sender.gender ? letter.sender.gender === UserDataDtoGenderEnum.Male ? "https://cdn-icons-png.flaticon.com/512/10031/10031337.png" : letter.sender.gender === UserDataDtoGenderEnum.Female ? "https://cdn-icons-png.flaticon.com/512/4939/4939798.png" : "https://cdn-icons-png.flaticon.com/512/1534/1534072.png" : "https://cdn-icons-png.flaticon.com/512/1534/1534072.png"}
                shape="square" size="large">{letter.sender.gender}</Avatar>
          </div>
          <div className="avatar-info">
            <Title
                level={5}>{letter.sender.firstName + " " + letter.sender.lastName}</Title>
            <p>{letter.sender.email}</p>
          </div>
        </Avatar.Group> : "N/A"),
  },
  {
    title: "Country",
    dataIndex: ["sender","country", "defaultLabel" ],
    key: "country",
  },
  {
    title: "Jamaat",
    dataIndex: ["sender","jamaat", "defaultLabel" ],
    key: "jamaat",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    render: (category) => (
        <Button type="text" style={{color: getCategoryColor(category),borderColor:getCategoryColor(category) ,borderStyle: 'solid'}}>
          {category}
        </Button>
    ),
  },
  {
    title: "Age",
    dataIndex: ["sender","birthdate" ],
    render: (birthdate) => (calculate_age(birthdate)),
  },
  {
    title: "Content",
    dataIndex: "content",
    key: "content",
    render: (content) => (content.substring(0, 20) + " ...")
  },
  {
    title: "Huzoor",
    dataIndex: ["process","presentToHudhur"],
    key: "hudhur",

    render: (hudhur) => (
        <div style={{
          backgroundColor: '#f0f0f0',
          color: 'grey',
          borderRadius: '30%',
          width: '24px',
          height: '24px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '12px',
          fontWeight: 'bold'
        }}>
          {hudhur}
        </div>
    ),
  },
  {
    title: "Status",
    key: "status",
    dataIndex: ["process","internalStatus"],
    render: (status) => (
        <Button type="text" style={{ backgroundColor: getStatusColor(status),color:'white' }}>
          {status}
        </Button>
    ),
  },
  {
    title: "By",
    dataIndex: ["process","assignedTo"],
    key: "by",
  },
];
  
  const data = [
    {
      key: "1",
      name: "Alim Ahmad Bhatti",
      country: "Germany",
      jamaat: "Friedberg",
      category: "Health",
      age: 12,
      status: "new",
      content: "Sample content",
      hudhur: "X",
      by: "AAB",
    },
    {
      key: "2",
      name: "Raziq Ahmad Tariq",
      country: "UK",
      jamaat: "Morden",
      category: "Study",
      age: 24,
      status: "answered",
      content: "Sample content",
      hudhur: "",
      by: "RAT",
    },
   
  ];
  
  function getCategoryColor(category) {
    // Define color mapping for categories
    const colorMap = {
      Health: "#ffd700",
      Study: "#4169e1",
      Rishta: "#ff6347",
      Jamaat: "#ff0000",
      Parents: "#32cd32",
      Jamia: "#ffa500",
      Children: "#1e90ff",
      Economy: "#8a2be2",
      Testcategory: "#e5005e",
    };
    return colorMap[category] || "#000000";
  }
  
  function getStatusColor(status) {
    // Define color mapping for statuses
    const colorMap = {
      new: "#1890ff",
      answered: "#52c41a",
      summarized: "#faad14",
      unclear: "#ff4d4f",
      LETTER_RECEIVED: "#186700",
    };
    return colorMap[status] || "#000000";
  }
  
  function WorkingMembers() {
    const dispatch = useDispatch();
    const {letters} = useSelector((state) => state.app);
    const [currentUser: UserDataDto, setCurrentUser] = useState(null);
    const [isLoading: boolean, setLoading] = useState(false);

    useEffect(() => {
      dispatch(loadLetters({}));
    }, []);
    
    return (
        <>
          <div className="tabled">
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                  <div className="table-responsive">
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={{pageSize: 10}}
                        className="ant-border-space"
                    />
                  </div>
              </Col>
            </Row>
          </div>
        </>
    );
  }

export default WorkingMembers;