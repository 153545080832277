import {
    AttachmentsControllerApi,
    Configuration, LetterManagementControllerApi
} from "ps-letter-mgmtrest-client";
import environments from "../utils/environments";
import {getAuthenticatedUser} from "../utils/authHelper";

class LetterManagementRestClient {

    constructor() {
    }

    accessToken = () => {
        return getAuthenticatedUser()?.access_token;
    };

    configuration = () => {
        const openapiConfig = new Configuration();
        openapiConfig.baseOptions = {
            headers: { Authorization: 'Bearer ' + this.accessToken() },
        };
        openapiConfig.basePath = environments.apiUrl;
        return openapiConfig;
    };
    
    letterMgmtApi = () => {
        return new LetterManagementControllerApi(this.configuration())
    }

    attachmentApi = () => {
        return new AttachmentsControllerApi(this.configuration())
    }
}

export default LetterManagementRestClient;