import {
    Avatar,
    Comment,
    Card,
    Typography,
    Row,
    Col,
    Divider,
    Space,
    Button,
    message,
    Spin,
    Select,
    Switch, Tag
} from "antd";
import {UserDataDtoGenderEnum} from "acc-rest-client";
import React, {useState} from "react";
import moment from "moment/moment";
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import {calculate_age} from "../tables/LetterTables";
import {StackDto} from "ps-letter-mgmtrest-client";
import {useDispatch, useSelector} from "react-redux";
import {updateLetter} from "../../store/app/app.slice";
import TextArea from "antd/es/input/TextArea";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import CreateSummaryDialog from "../dialogs/CreateSummaryDialog";
import {ProcessedLetterDto} from "ps-letter-mgmtrest-client";
import {AnswerDto} from "ps-letter-mgmtrest-client";
import CreateAnswerDialog from "../dialogs/CreateAnswerDialog";
import {getLanguagesLabel, getTopicsLabel, getTopicsLabelUrdu} from "../helper/labelHelper";

const {Title} = Typography;
export default function StackLetter({letter, stack, onLetterProcessed}: {letter: ProcessedLetterDto, stack: StackDto}) {
    const dispatch = useDispatch();
    const {isLetterLoading, summaries, answers} = useSelector((state) => state.app);
    const [showCreateSummaryModal, setShowCreateSummaryDialog] = useState(false);
    const [showCreateAnswerModal, setShowCreateAnswerDialog] = useState(false);

    const [summaryId, setSummaryId] = useState(letter?.summary?.id);
    const [summaryNotes, setSummaryNotes] = useState(letter?.summaryNotes);
    const [adviceFromHudhur, setAdviceFromHudhur] = useState(letter?.adviceFromHudhur);
    const [answerId, setAnswerId] = useState(letter?.answer?.id);
    const [customAnswer, setCustomAnswer] = useState(letter?.customAnswer);
    const isUrduLanguage = letter.originalLetter.language === "urdu" || letter.originalLetter.language === "roman_urdu"
    const currentLetterTopic = letter.originalLetter.category
    const currentLetterSummary = letter.summary
    
    const openCreateSummaryModal = () => {
        setShowCreateSummaryDialog(true);
    };

    const hideCreateSummaryModalModal = () =>  {
        setShowCreateSummaryDialog(false);
    }

    const openCreateAnswerModal = () => {
        setShowCreateAnswerDialog(true);
    };

    const hideCreateAnswerModalModal = () =>  {
        setShowCreateAnswerDialog(false);
    }
    
    const updateLetterFiltration = (presentToHudhur: any) => {
        dispatch(updateLetter(
            {
                finalData: {
                                id: letter.id,
                                updatedLetter: {
                                        id: letter.id,
                                        presentToHudhur: presentToHudhur,
                                }
                            },
                callbackSuccess: (result) => {
                    console.log(result)
                    onLetterProcessed(result)
                },
                callbackError: (error) => {
                    console.log(error)
                    message.error({content: JSON.stringify(error)})
                },
            }
          )
        )
    }

    const updateLetterSummary = (summaryId, summaryNotes, adviceFromHudhur) => {
        dispatch(updateLetter(
                {
                    finalData: {
                        id: letter.id,
                        updatedLetter: {
                            id: letter.id,
                            summary: {id: summaryId},
                            summaryNotes: summaryNotes,
                            adviceFromHudhur: adviceFromHudhur
                        }
                    },
                    callbackSuccess: (result) => {
                        console.log(result)
                        onLetterProcessed(result)
                    },
                    callbackError: (error) => {
                        console.log(error)
                        message.error({content: JSON.stringify(error)})
                    },
                }
            )
        )
    }

    const updateLetterAnswer = (answerId, customAnswer) => {
        dispatch(updateLetter(
                {
                    finalData: {
                        id: letter.id,
                        updatedLetter: {
                            id: letter.id,
                            answer: {id: answerId},
                            customAnswer: customAnswer
                        }
                    },
                    callbackSuccess: (result) => {
                        console.log(result)
                        onLetterProcessed(result)
                    },
                    callbackError: (error) => {
                        console.log(error)
                        message.error({content: JSON.stringify(error)})
                    },
                }
            )
        )
    }
    
    const getFiltrationControls = () => {
        return <>
            <Spin spinning={isLetterLoading === true}>
                <Row align={"middle"} justify={"space-between"}>
                    <Col span={12} flex={"none"}>
                        <b>Current Value:</b> {letter.presentToHudhur ? <>Present to Huzoor<sup>aa</sup></> : letter.presentToHudhur === false ?  <>Summarize Letter</> : "Not Defined"}
                    </Col>
                    <Col span={12} flex={"none"}>
                            <Button type="default" onClick={() => {updateLetterFiltration(null)}}>Skip</Button>
                    </Col>
                </Row>
            <Divider/>
            <Row align={"middle"} justify={"center"}>
                <Col span={24} flex={"none"}>
                    <Space align={ "center"}>
                    <Button type="primary" onClick={() => {updateLetterFiltration(true)}}>Present to Huzoor<sup>aa</sup></Button>
                    <Button type="default" className={"tag-badge"} onClick={() => {updateLetterFiltration(false)}}>Summarize Letter</Button>
                    </Space>
                </Col>
            </Row>
                </Spin>
        </>
            }

    const getSummaryControls = () => {
        return <>
            <Spin spinning={isLetterLoading === true}>
                <Row align={"middle"} justify={"space-between"}>
                    <Col span={18} flex={"none"}>
                        <b>Current Summary:</b><br/> {letter.summary ? <><span className={"arabic-font-small arabic-font"}>{letter.summary.titleUrdu}</span></> : "Summary not assigned"}
                    </Col>
                    <Col span={6} flex={"none"}>
                        <Button type="default" onClick={() => {updateLetterSummary(letter?.summary?.id, letter?.summaryNotes, letter?.adviceFromHudhur)}}>Skip</Button>
                        <Button type="default" className={"ant-tag-success"} onClick={() => {updateLetterSummary(summaryId, summaryNotes, adviceFromHudhur)}}>Save</Button>
                    </Col>
                </Row>
                <Divider/>
                <Row align={"middle"} justify={"center"}>
                    <Col span={24} flex={"none"}>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Select
                                showSearch
                                autoFocus
                                popupClassName={"arabic-font arabic-font-edit"}
                                className={"arabic-font arabic-font-edit"}
                                style={{ width: "550px" }}
                                size={"large"}
                                placeholder="Select a Summary"
                                optionFilterProp="children"
                                onChange={setSummaryId}
                                //onSearch={onSearch}
                                filterOption={(input, option) =>
                                    ((option?.titleUrdu) ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                fieldNames={{ label: "titleUrdu", value: "id"}}
                                options={summaries}
                                value={summaryId}
                                option
                            />
                            <Button type="default" className={"ant-tag-geekblue"} onClick={openCreateSummaryModal}>+</Button>
                        </div>
                        <br/>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <h3>Additional Notes for Huzoor</h3>
                        </div>
                        <div style={{display: "flex", flexFlow: "column", justifyContent: "center"}}>
                            <TextArea className={"arabic-font arabic-font-edit"} rows={6} size={"large"} value={summaryNotes} onChange={e => setSummaryNotes(e.target.value)}/>
                            <span><Switch checked={adviceFromHudhur} onChange={setAdviceFromHudhur} size={"large"} checkedChildren={<>Advice <CheckOutlined /></>} unCheckedChildren={<>Advice <CloseOutlined /></>} /></span>
                            

                        </div>
                    </Col>
                </Row>
                <CreateSummaryDialog showModal={showCreateSummaryModal} onHideModal={hideCreateSummaryModalModal} topic={currentLetterTopic}/>
            </Spin>
        </>
    }

    const getAnswerControls = () => {
        return <>
            <Spin spinning={isLetterLoading === true}>
                <Row align={"middle"} justify={"space-between"}>
                    <Col span={18} flex={"none"}>
                        <b>Current Answer:</b><br/> {letter.customAnswer ? "Customized Answer" :  letter.answer ? isUrduLanguage ? <><span className={"arabic-font-small arabic-font"}>{letter.summary.titleUrdu}</span></> : letter.answer.titleEnglish : "Answer not assigned"}
                    </Col>
                    <Col span={6} flex={"none"}>
                        <Button type="default" onClick={() => {updateLetterAnswer(letter?.answer?.id, letter?.customAnswer)}}>Skip</Button>
                        <Button type="default" className={"ant-tag-success"} onClick={() => {updateLetterAnswer(answerId, customAnswer)}}>Save</Button>
                    </Col>
                </Row>
                <Divider/>
                <Row align={"middle"} justify={"center"}>
                    <Col span={24} flex={"none"}>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <Select
                                showSearch
                                autoFocus
                                style={{ width: "550px" }}
                                popupClassName={isUrduLanguage ? "arabic-font arabic-font-edit" : ""}
                                className={isUrduLanguage ? "arabic-font arabic-font-edit": ""}
                                size={"large"}
                                placeholder="Select a Answer"
                                optionFilterProp="children"
                                onChange={(value) => {
                                    setCustomAnswer(null)
                                    setAnswerId(value)}
                                }
                                //onSearch={onSearch}
                                filterOption={(input, option) =>
                                    ((isUrduLanguage ? option?.titleUrdu : option?.titleEnglish) ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                fieldNames={{ label:  isUrduLanguage ? "titleUrdu" : "titleEnglish", value: "id"}}
                                options={answers}
                                value={answerId}
                                option
                            />
                            <Button type="default" className={"ant-tag-geekblue"} onClick={openCreateAnswerModal}>+</Button>
                        </div>
                        <br/>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <h3>Answer</h3>
                        </div>
                        <div style={{display: "flex", flexFlow: "column", justifyContent: "center"}}>
                            <TextArea className={getTextClass()} rows={6} size={"large"} value={getCurrentAnswer()} onChange={e => setCustomAnswer(e.target.value)}/>
                        </div>
                    </Col>
                </Row>
                <CreateAnswerDialog showModal={showCreateAnswerModal} onHideModal={hideCreateAnswerModalModal} isUrduLanguage={isUrduLanguage} topic={currentLetterTopic} summary={currentLetterSummary?.id}/>
            </Spin>
        </>
    }
    
    const getCurrentAnswer = () => {
        if(customAnswer !== null && customAnswer !== undefined)
            return customAnswer;
        if(answerId !== null && answerId !== undefined)
        {
            const selectedAnswer: AnswerDto = answers.find(answer => answer.id === answerId);
            switch(letter?.originalLetter?.language)
            {
                case "urdu":
                case "roman_urdu":
                    return selectedAnswer?.answerUrdu
                case "deutsch":
                    return selectedAnswer?.answerGerman
                default:
                    return selectedAnswer?.answerEnglish;
            }    
        }
        return null
    }

    const getTextClass = () => {
        if(isUrduLanguage) return "arabic-font arabic-font-edit";
        return "";
    }

    const getStackControls = () => {
        switch (stack?.assignedStackType) {
            case"FILTRATION":
                switch (letter?.internalStatus) {
                    case "LETTER_IN_FILTRATION":
                    case "LETTER_FILTERED":
                        return getFiltrationControls()
                }
            case"SUMMARY":
                switch (letter?.internalStatus) {
                    case "LETTER_IN_SUMMARY":
                    case "LETTER_SUMMARIZED":
                        return getSummaryControls()
                }
            case"ANSWER":
                switch (letter?.internalStatus) {
                    case "LETTER_IN_ANSWER":
                    case "LETTER_ANSWERED":
                        return getAnswerControls()
                }
        }
    }
    
    return <>
        <Row justify={"start"} style={{height: "100%"}}>
            <Col span={12}>
                <div style={{height: "100%"}}>
                    <Card style={{height: "100%"}}>
                        <Comment
                            content={<>
                                <div>
                                    <Row>
                                        <Col span={16}>
                                            <Avatar.Group>
                                                <div style={{marginRight: "3px"}}>
                                                    <Avatar
                                                        src={letter.originalLetter.sender.gender ? letter.originalLetter.sender.gender === UserDataDtoGenderEnum.Male ? "https://cdn-icons-png.flaticon.com/512/10031/10031337.png" : letter.originalLetter.sender.gender === UserDataDtoGenderEnum.Female ? "https://cdn-icons-png.flaticon.com/512/4939/4939798.png" : "https://cdn-icons-png.flaticon.com/512/1534/1534072.png" : "https://cdn-icons-png.flaticon.com/512/1534/1534072.png"}
                                                        shape="square"
                                                        size="large">{letter.originalLetter.sender.gender}</Avatar>
                                                </div>
                                                <div className="avatar-info">
                                                    <Title
                                                        level={5}>{letter.originalLetter.sender.firstName + " " + letter.originalLetter.sender.lastName} ({calculate_age(letter.originalLetter.sender.birthdate)}){ letter.originalLetter.sender.urduName ? <span className={"arabic-font arabic-font-edit"}> - {letter.originalLetter.sender.urduName}</span> : "" } </Title>
                                                    <p>
                                                        <span>{getUnicodeFlagIcon(letter.originalLetter.sender.country?.values?.flag)} {letter.originalLetter.sender.country ? letter.originalLetter.sender.country.defaultLabel : ""} </span>{letter.originalLetter.sender.jamaat ? "(" + letter.originalLetter.sender.jamaat + ")" : "no jamaat"}
                                                    </p>
                                                </div>
                                            </Avatar.Group>
                                        </Col>
                                        <Col span={8}>
                                            <div className="avatar-info">
                                                <p>{moment.utc(letter.originalLetter.metaData.created).format("DD-MM-yyyy HH:mm (UTC)")}</p>
                                                
                                                <p>
                                                    <Tag  color="blue">{isUrduLanguage ? <span style={{verticalAlign: "sub", height: "25px"}} className={"arabic-font arabic-font-extra-small"}>{getTopicsLabelUrdu(letter.originalLetter.category)}</span> : getTopicsLabel(letter.originalLetter.category)}</Tag>
                                                    <Tag  color="gold">{isUrduLanguage ? <span style={{verticalAlign: "sub", height: "25px"}} className={"arabic-font arabic-font-extra-small"}>{getLanguagesLabel(letter.originalLetter.language)}</span> : getLanguagesLabel(letter.originalLetter.language)}</Tag>
                                                </p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Divider />
                                    <p className={isUrduLanguage ? "arabic-font" : "roman-font"} >{letter.originalLetter.content}</p>
                                </div>
                            </>}
                        />
                    </Card>
                </div>
            </Col>
            <Col span={12}>
                    <div style={{height: "100%"}}>
                        <Card style={{height: "100%"}}>
                                {getStackControls(stack)}
                        </Card>
                    </div>
            </Col>
        </Row>
    </>
}