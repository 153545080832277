import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {useState} from "react";
import {CreateStackRequestDto} from "ps-letter-mgmtrest-client";
import {createStackRequest, createSummary} from "../../store/app/app.slice";
import {Form, InputNumber, message, Modal, Result, Select} from "antd";
import {CreateSummaryDto} from "ps-letter-mgmtrest-client";
import TextArea from "antd/es/input/TextArea";
import {getTopicsOptions, getTopicsOptionsUrdu} from "../helper/labelHelper";

const { Option } = Select;
export default function CreateSummaryDialog(props) {

    const dispatch = useDispatch();

    const [topics, setTopics] = useState((props?.topic) ? [props.topic] : []);
    const [titleUrdu, setTitleUrdu] = useState("");
    
    const hideModal = () =>  {
        props.onHideModal()
    }
    
    const handleOk = () =>  {
        const summary: CreateSummaryDto = {
            titleUrdu: titleUrdu,
            titleEnglish: "",
            topics: topics
        }
        dispatch(createSummary({
            finalData: {
                summary: summary
            },
            callbackSuccess: (result) => {
                console.log(result)
                hideModal();
            },
            callbackError: (error) => {
                console.log(error)
                message.error({content: JSON.stringify(error)})
            },
        }));
    }
    
    return <Modal
            open={props.showModal}
            title={"Create a new Summary"}
            onOk={handleOk}
            okText={"Ok"}
            onCancel={hideModal}
            width={1000}
        >
        <Form.Item label="Summary title in Urdu">
            <TextArea className={"arabic-font arabic-font-edit"} rows={6} size={"large"} value={titleUrdu}
                         onChange={e => setTitleUrdu(e.target.value)}/>
        </Form.Item>
            

            <Form.Item label="Topics">
                <Select
                    mode="multiple"
                    style={{width: '100%'}}
                    allowClear
                    popupClassName={"arabic-font arabic-font-edit"}
                    className={"arabic-font arabic-font-extra-small"}
                    placeholder={"Choose Topics"}
                    value={topics}
                    onChange={setTopics}
                >
                    {getTopicsOptionsUrdu()}
                </Select>
            </Form.Item>
        </Modal>
}