import {useDispatch, useSelector} from "react-redux";
import MixedStacksTable from "../tables/MixedStacksTable";
import {useParams} from "react-router-dom";
import EmptyStack from "./EmptyStack";
import StackDetailTable from "../tables/StackDetailTable";
import {useEffect, useState} from "react";
import {loadLettersByStack, loadStack} from "../../store/app/app.slice";
import CreateRequestStackDialog from "../dialogs/CreateRequestStackDialog";
import {message, Spin} from "antd";

export default function StackDetail(props) {
    const dispatch = useDispatch();
    const params = useParams();
    const {lettersByStack} = useSelector((state) => state.app);
    const {currentStack} = useSelector((state) => state.app);

    const [openStackRequestDialog, setOpenStackRequestDialog] = useState(false);
    const showModal = () => {
        setOpenStackRequestDialog(true);
    };

    const hideModal = () =>  {
        setOpenStackRequestDialog(false);
    }
    
    useEffect(() => {
        refreshStack()
    }, []);
    
    const refreshStack = () => {
        dispatch(loadStack({finalData: {id: params?.stackId}}))
        dispatch(loadLettersByStack({
                finalData: {stackId: params?.stackId},
                callbackError: (error) => {
                    message.error({content: error})
            }
        }))
    }
    
    return <>
        { (currentStack && lettersByStack && ""+currentStack.id === params.stackId) ? <StackDetailTable key={params.stackId} letters={lettersByStack} stack={currentStack} showNewStackRequestModal={showModal} inProcess={props.inProcess} refreshStack={refreshStack}/> : <Spin tip="Loading..."></Spin>}
        <CreateRequestStackDialog showModal={openStackRequestDialog} onHideModal={hideModal}/>
    </>
}