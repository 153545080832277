import { User, UserProfile } from "oidc-client-ts"
import {oidcConfig} from "../index";

export interface AMJ_User extends User{
    isAccountVerified(): boolean,
    isEmailVerified(): boolean,
    isAdmin(): boolean,
    isPortalUser(): boolean,
    isPortalSupervisor(): boolean,
    roles(): [] 
}

export function getAuthenticatedUser(): AMJ_User {
    const oidcStorage = sessionStorage.getItem("oidc.user:"+oidcConfig.authority+":"+oidcConfig.client_id)
    if (!oidcStorage) {
        return null;
    }
    const currentUser: User = User.fromStorageString(oidcStorage);
    return { ...currentUser,
    
        isAccountVerified: () => {
            return currentUser.profile["amj-account-verified"] === "true"
        },
        isEmailVerified: () => {
            return currentUser.profile["email_verified"] === true
        },
        isAdmin() {
            return currentUser.profile.realm_access?.roles?.includes('amj-international-admin')
        },
        isPortalUser() {
            return currentUser.profile.realm_access?.roles?.includes('amj-portal-member') || currentUser.profile.realm_access?.roles?.includes('amj-portal-supervisor')
        },
        isPortalSupervisor() {
            return currentUser.profile.realm_access?.roles?.includes('amj-portal-supervisor')
        },
    };
}

export function getUserProfile(): UserProfile {
    return getAuthenticatedUser()?.profile;
}

export function getUserRoles(): [] {
    return getUserProfile()?.realm_access?.roles ?? [] ;
}