import {ProcessedLetterDto} from "ps-letter-mgmtrest-client";
import {Select} from "antd";
const { Option } = Select;

export const languages = {
    english: "English",
    urdu: "Urdu",
    deutsch: "Deutsch",
    roman_urdu: "Roman Urdu",
};

export const topics = {
    english: {
        health_illness: "Health/Illness",
        studies_education: "Studies/Education",
        family_marital_issues: "Family/Marital Issues",
        spiritual_religious_matters: "Spiritual/Religious Matters",
        career_employment: "Career/Employment",
        financial_issues: "Financial Issues",
        marriage_proposals: "Marriage Proposals",
        children_parenting: "Children/Parenting",
        distress_challenges: "Distress/Challenges",
        religious_jamaat_work_responsibilities: "Religious and Jamaat Work/Responsibilities",
        dreams_interpretations: "Dreams/Interpretations",
        religious_devotions_personal_pledges: "Religious Devotions/Personal Pledges",
        special_occasions: "Special Occasions",
        immigration_legal_matters: "Immigration/Legal Matters",
        anxiety_mental_health: "Anxiety/Mental Health",
        relocation_moving: "Relocation/Moving",
        difficulties_faith_religious_practice: "Difficulties in Faith/Religious Practice",
        prayers_deceased_loved_ones: "Prayers for Deceased Loved Ones",
        gratitude_thanks: "Gratitude and Thanks",
        hajj_umrah_related: "Hajj and Umrah-Related",
        business_investment: "Business/Investment",
        other: "Other",
    },
    urdu: {
        advice_guidance: "مشورہ / راہنمائی (Mashwara / Rahnumai)",
        health_illness: "صحت / بیماری (Sehat / Bimari)",
        studies_education: "تعلیم (Taleem)",
        family_marital_issues: "گھریلو / عائلی مسائل (Gharelu / Aili Masail)",
        spiritual_religious_matters: "روحانی / مذہبی معاملات (Ruhani / Mazhabi Mamlaat)",
        career_employment: "ملازمت / روزگار (Mulazmat / Rozgar)",
        financial_issues: "مالی مسائل (Mali Masail)",
        marriage_proposals: "رشتے کے معاملات (Rishtey ke Mamlaat)",
        children_parenting: "بچے / تربیت (Bachchay / Tarbiyat)",
        distress_challenges: "پریشانیاں / مشکلات (Pareshaniyan / Challenges)",
        religious_jamaat_work_responsibilities: "جماعتی ذمہ داریاں / دینی فرائض (Jamaati Zimmadariyan / Deeni Faraiz)",
        dreams_interpretations: "خواب / تعبیر (Khwab / Tabir)",
        religious_devotions_personal_pledges: "اخلاص و وفا / عقیدت (Ikhlas o Wafa / Aqeedat)",
        special_occasions: "خاص مواقع (Khas Moqai)",
        immigration_legal_matters: "امیگریشن / قانونی معاملات (Immigration / Qanooni Mamlaat)",
        anxiety_mental_health: "ذہنی صحت / پریشانی (Zehni Sehat / Pareshani)",
        relocation_moving: "نقل مکانی (Naql Makani)",
        difficulties_faith_religious_practice: "مذہبی اور دینی مشکلات (Mazhabi aur Deeni Mushkilat)",
        prayers_deceased_loved_ones: "مرحومین کےلئے دعا مغفرت (Marhumeen ke liye Dua Maghfirat)",
        gratitude_thanks: "اظہار تشکر (Izhaar Tashakkur)",
        hajj_umrah_related: "حج اور عمرہ سے متعلق (Hajj aur Umrah se Mutaliq)",
        business_investment: "کاروبار / سرمایہ کاری (Karoorbar / Sarmayakari)",
        other: "متفرق (Mutafarriq)",
    }
};

export const letterStatusLabels = {
    "LETTER_RECEIVED": "New Letter",
    "LETTER_IN_FILTRATION": "Filtration",
    "LETTER_FILTERED": "Filtered",
    "LETTER_PRESENTED_TO_HUDHUR": "Present To Hudhur",
    "LETTER_READY_FOR_SUMMARY": "Ready For Summary",
    "LETTER_IN_SUMMARY": "Summarizing",
    "LETTER_SUMMARIZED": "Summarized",
    "LETTER_SUMMARY_PRESENTED_TO_HUDHUR": "Summary printed",
    "LETTER_READY_FOR_ANSWER": "Ready For Answer",
    "LETTER_IN_ANSWER": "Answering",
    "LETTER_ANSWERED": "Answered",
    "LETTER_ANSWER_PRINTED": "Answer Printed",
    "LETTER_TOTALLY_PROCESSED": "Closed",
    "LETTER_MANUALLY_CLOSED": "Closed (manually)"
}

export const stackStatusLabels = {
    "REQUESTED": "Requested",
    "ASSIGNED": "Assigned",
    "DELIVERED": "Delivered",
    "SUBMITTED": "Submitted",
    "CLOSED": "Closed",
}

export const stackTypeLabels = {
    "FILTRATION": "Filtration",
    "SUMMARY": "Summary",
    "ANSWER": "Answers",
}

export const letterStatusFilterDropdown = Object.keys(letterStatusLabels).map((key) => {
    return { text: letterStatusLabels[key], value: key };
})

export const letterLanguageFilterDropdown = Object.keys(languages).map((key) => {
    return { text: languages[key], value: key };
})

export const stackStatusFilterDropdown = Object.keys(stackStatusLabels).map((key) => {
    return { text: stackStatusLabels[key], value: key };
})
export const stackTypeFilterDropdown = Object.keys(stackTypeLabels).map((key) => {
    return { text: stackTypeLabels[key], value: key };
})

export const getStackStatusLabel = (stackStatus) => {
    const status = stackStatus;
    return stackStatusLabels[status] || status; // Default to red if no match
}

export const getStackTypeLabel = (stackType) => {
    const type = stackType;
    return stackTypeLabels[type] || type; // Default to red if no match
}


export const getProcessedLetterStatusLabel = (processedLetter: ProcessedLetterDto): string => {
    const status = processedLetter.internalStatus;
    return  letterStatusLabels[status] || status; // Default to red if no match
}

// Base colors
const letterStatusBaseColors = {
    "LETTER_RECEIVED": "#8f9191",
    "LETTER_IN_FILTRATION": "#5c5959",
    "LETTER_FILTERED": "#3c3a3a",
    "LETTER_PRESENTED_TO_HUDHUR": "#186700",
    "LETTER_READY_FOR_SUMMARY": "#e89d69",
    "LETTER_IN_SUMMARY": "#bc7034",
    "LETTER_SUMMARIZED": "#884712",
    "LETTER_SUMMARY_PRESENTED_TO_HUDHUR": "#186700",
    "LETTER_READY_FOR_ANSWER": "#034bdc",
    "LETTER_IN_ANSWER": "#013587",
    "LETTER_ANSWERED": "#00215a",
    "LETTER_ANSWER_PRINTED": "#186700",
    "LETTER_TOTALLY_PROCESSED": "#000000",
    "LETTER_MANUALLY_CLOSED": "#1c1c1c",
};

export const getProcessedLetterStatusColor = (processedLetter:ProcessedLetterDto) => {
    const colorStatus = processedLetter.internalStatus;
    return  letterStatusBaseColors[colorStatus] || "#ff0000"; // Default to red if no match
}


export const getLanguagesLabel = (value) => {
    return getLabels(value, languages)
}

export const  getLanguagesOptions = () => {
    return  getOptions(languages)
}

export const  getTopicsLabel = (value) => {
    return getLabels(value, topics.english)
}

export const  getTopicsLabelUrdu = (value) => {
    return getLabels(value, topics.urdu)
}

export const  getTopicsOptions = () => {
    return  getOptions(topics.english)
}

export const  getTopicsOptionsUrdu = () => {
    return  getOptions(topics.urdu)
}

export const  getOptions = (objectWithKeyValue) => {
    return Object.entries(objectWithKeyValue).map((entry) => {
        return <Option key={entry[0]} value={entry[0]}>{entry[1]}</Option>
    })
}

export const  getLabels = (value, objectWithKeyValue) => {
    const matchedEntry = Object.entries(objectWithKeyValue).find((entry) => entry[0] === value)
    return matchedEntry ? matchedEntry[1] : value
}