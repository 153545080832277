import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import React, {useState} from "react";
import {CreateStackRequestDto} from "ps-letter-mgmtrest-client";
import {createAnswer, createStackRequest, createSummary} from "../../store/app/app.slice";
import {Form, InputNumber, message, Modal, Result, Select} from "antd";
import {CreateSummaryDto} from "ps-letter-mgmtrest-client";
import TextArea from "antd/es/input/TextArea";
import {CreateAnswerDto} from "ps-letter-mgmtrest-client";
import {getTopicsOptions, getTopicsOptionsUrdu} from "../helper/labelHelper";

const { Option } = Select;

export default function CreateAnswerDialog(props) {

    const dispatch = useDispatch();
    
    const [topics, setTopics] = useState((props?.topic) ? [props.topic] : []);
    const [selectedSummaries, setSelectedSummaries] = useState((props?.summary) ? [props.summary] : []);
    const [titleUrdu, setTitleUrdu] = useState("");
    const [titleEnglish, setTitleEnglish] = useState("");
    const [answerEnglish, setAnswerEnglish] = useState("");
    const [answerUrdu, setAnswerUrdu] = useState("");
    const [answerGerman, setAnswerGerman] = useState("");
    const {summaries} = useSelector((state) => state.app);
    const isUrduLanguage = props.isUrduLanguage
    
    const hideModal = () =>  {
        props.onHideModal()
    }
    
    const handleOk = () =>  {
        const selectedSummariesItem = selectedSummaries?.map((summary) => summaries.find((item) => item.id === summary));
        const newAnswer: CreateAnswerDto = {
            titleUrdu: titleUrdu,
            titleEnglish: titleEnglish,
            answerEnglish: answerEnglish,
            answerGerman: answerGerman,
            answerUrdu: answerUrdu,
            summaries: selectedSummariesItem,
            topics: topics
        }
        dispatch(createAnswer({
            finalData: {
                answer: newAnswer
            },
            callbackSuccess: (result) => {
                console.log(result)
                setAnswerGerman("")
                setAnswerEnglish("")
                setAnswerUrdu("")
                setTopics([])
                setSelectedSummaries([])
                setTitleUrdu("")
                setTitleEnglish("")
                hideModal();
            },
            callbackError: (error) => {
                console.log(error)
                message.error({content: JSON.stringify(error)})
            },
        }));
    }
    
    return <Modal
            open={props.showModal}
            title={"Create a new Answer"}
            onOk={handleOk}
            okText={"Ok"}
            onCancel={hideModal}
            width={1000}
        >
        <Form.Item label="Answer title in English">
            <TextArea  rows={1} size={"large"} value={titleEnglish}
                       onChange={e => setTitleEnglish(e.target.value)}/>
        </Form.Item>
        <Form.Item label="Answer title in Urdu">
            <TextArea  className={"arabic-font arabic-font-edit"} rows={1} size={"large"} value={titleUrdu}
                         onChange={e => setTitleUrdu(e.target.value)}/>
        </Form.Item>

        <Form.Item label="Answer in English">
            <TextArea  rows={4} size={"large"} value={answerEnglish}
                       onChange={e => setAnswerEnglish(e.target.value)}/>
        </Form.Item>
        
        <Form.Item label="Answer in German">
            <TextArea  rows={4} size={"large"} value={answerGerman}
                       onChange={e => setAnswerGerman(e.target.value)}/>
        </Form.Item>
        
        <Form.Item label="Answer in Urdu">
            <TextArea  className={"arabic-font arabic-font-edit"} rows={4} size={"large"} value={answerUrdu}
                       onChange={e => setAnswerUrdu(e.target.value)}/>
        </Form.Item>

        <Form.Item label="Topics">
            <Select
                mode="multiple"
                style={{width: '100%'}}
                allowClear
                placeholder={"Choose Topics"}
                popupClassName={isUrduLanguage ? "arabic-font arabic-font-edit" : ""}
                className={isUrduLanguage ? "arabic-font arabic-font-extra-small": ""}
                value={topics}
                onChange={setTopics}
            >
                {isUrduLanguage ? getTopicsOptionsUrdu() : getTopicsOptions()}
            </Select>
        </Form.Item>

        <Form.Item label="Summaries">
            <Select
                mode="multiple"
                style={{width: '100%'}}
                allowClear
                popupClassName={isUrduLanguage ? "arabic-font arabic-font-edit" : ""}
                className={isUrduLanguage ? "arabic-font arabic-font-extra-small": ""}
                placeholder={"Choose Summaries"}
                value={selectedSummaries}
                onChange={setSelectedSummaries}
                fieldNames={{ label: isUrduLanguage ?"titleUrdu" : "titleEnglish", value: "id"}}
                options={summaries}
                option
            />
        </Form.Item>
        </Modal>
}