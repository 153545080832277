import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Avatar,
    Typography, Space, notification, Tag,
} from "antd";

import {
    DownloadOutlined,
    FileWordOutlined,
} from "@ant-design/icons";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {UserDataDtoGenderEnum} from "acc-rest-client";
import {useEffect, useRef, useState} from "react";
import {
    downloadAttachment,
    downloadLettersForHudhur,
    loadLetters,
    searchLettersToPresentToHudhur
} from "../../store/app/app.slice";
import moment from "moment/moment";
import {ProcessedLetterDto} from "ps-letter-mgmtrest-client";
import {useReactToPrint} from 'react-to-print';
import {
    getLanguagesLabel,
    getProcessedLetterStatusColor,
    getProcessedLetterStatusLabel,
    getTopicsLabel, letterLanguageFilterDropdown,
    letterStatusFilterDropdown
} from "../helper/labelHelper";

import gender_male2_icon from "../../assets/images/gender_male2_icon.png";
import gender_female_icon from "../../assets/images/gender_female_icon.png";
import gender_unknown_icon from "../../assets/images/gender_unknown_icon.png";

const {Title} = Typography;

export const calculate_age = (dob1) => {
    var today = new Date();
    var birthDate = moment.utc(dob1);  // create a date object directly from `dob1` argument
    //var birthDate = moment.utc(dob1);  // create a date object directly from `dob1` argument
    var age_now = today.getFullYear() - birthDate.year();
    // var m = today.getMonth() - birthDate.month();
    // if (m < 0 || (m === 0 && today.getDate() < birthDate.date()))
    // {
    //   age_now--;
    // }
    console.log(age_now);
    return age_now;
}


function getCategoryColor(category) {
    // Define color mapping for categories
    const colorMap = {
        Health: "#ffd700",
        Study: "#4169e1",
        Rishta: "#ff6347",
        Jamaat: "#ff0000",
        Parents: "#32cd32",
        Jamia: "#ffa500",
        Children: "#1e90ff",
        Economy: "#8a2be2",
        Testcategory: "#e5005e",
    };
    return colorMap[category] || "#000000";
}

function LetterTable({filtered}) {
    const dispatch = useDispatch();
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const {letters, lettersPresentToHudhur}: {
        letters: ProcessedLetterDto,
        lettersPresentToHudhur: ProcessedLetterDto
    } = useSelector((state) => state.app);
    const history = useHistory();
    const params = useParams();

    const navigateToLetterView = (record: ProcessedLetterDto) => {
        history.push("/letters/" + record.id)
    }

    useEffect(() => {
        filtered === "toHudhur" ? dispatch(searchLettersToPresentToHudhur({})) : dispatch(loadLetters({}));
    }, [filtered]);

    const columnsLetters = [
        {
            title: "Name",
            dataIndex: "originalLetter",
            sorter: (a, b) => a.originalLetter.sender.firstName.localeCompare(b.originalLetter.sender.firstName),
            render: (letter, record) => (letter ?

                <a type="link" onClick={() => navigateToLetterView(record)}>
                    <Avatar.Group>
                        <div style={{marginRight: "3px"}}>
                            <Avatar
                                src={letter.sender.gender ? letter.sender.gender === UserDataDtoGenderEnum.Male ? gender_male2_icon : letter.sender.gender === UserDataDtoGenderEnum.Female ? gender_female_icon : gender_unknown_icon : gender_unknown_icon}
                                shape="square" size="large">{letter.sender.gender}</Avatar>
                        </div>
                        <div className="avatar-info">
                            <Title
                                level={5}>{letter.sender.firstName + " " + letter.sender.lastName}</Title>
                            <p>{letter.sender.email}</p>
                        </div>
                    </Avatar.Group>
                </a> : "N/A"),
        },
        {
            title: "Country",
            dataIndex: ["originalLetter", "sender", "country", "defaultLabel"],
            key: "country",
            sorter: (a, b) => a.originalLetter.sender.country.defaultLabel.localeCompare(b.originalLetter.sender.country.defaultLabel),
        },
        {
            title: "Jamaat",
            dataIndex: ["originalLetter", "sender", "jamaat"],
            sorter: (a, b) => a.originalLetter.sender.jamaat.localeCompare(b.originalLetter.sender.jamaat),
            key: "jamaat",
        },
        {
            title: "Topic",
            dataIndex: ["originalLetter", "category"],
            key: "category",
            sorter: (a, b) => a.originalLetter.category.localeCompare(b.originalLetter.category),
            render: (category) => (
                <Button type="text" style={{
                    color: getCategoryColor(category),
                    borderColor: getCategoryColor(category),
                    borderStyle: 'solid'
                }}>
                    {getTopicsLabel(category)}
                </Button>
            ),
        },
        {
            title: "Age",
            dataIndex: ["originalLetter", "sender", "birthdate"],
            sorter: (a, b) => a.originalLetter.sender.birthdate.localeCompare(b.originalLetter.sender.birthdate),
            render: (birthdate) => (calculate_age(birthdate)),
        },
        {
            title: "Language",
            dataIndex: ["originalLetter", "language"],
            filters: letterLanguageFilterDropdown,
            onFilter: (value, record) => record.originalLetter.language.includes(value),
            sorter: (a, b) => a.originalLetter.language.localeCompare(b.originalLetter.language),
            render: (language) => (getLanguagesLabel(language)),
        },
        {
            title: "Content",
            dataIndex: ["originalLetter", "content"],
            key: "content",
            sorter: (a, b) => a.originalLetter.content.localeCompare(b.originalLetter.content),
            render: (content) => (content.substring(0, 20) + " ...")
        },
        {
            title: "Huzoor",
            dataIndex: ["presentToHudhur"],
            key: "hudhur",
            filters: [
                {
                    text: 'Present to Huzoor',
                    value: true,
                },
                {
                    text: 'Summarize',
                    value: false,
                },
                {
                    text: 'Not defined',
                    value: null,
                },
            ],
            onFilter: (value, record) => record.presentToHudhur === value,
            sorter: (a, b) => a.presentToHudhur - b.presentToHudhur,
            render: (presentToHudhur) => (
                <div style={{
                    backgroundColor: '#f0f0f0',
                    color: presentToHudhur ? 'green' : presentToHudhur === false ? 'red' : 'grey',
                    borderRadius: '30%',
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '12px',
                    fontWeight: 'bold'
                }}>
                    {presentToHudhur ? '✓' : presentToHudhur === false ? '❌' : ''}
                </div>
            ),
        },
        {
            title: "Attachments",
            key: "attachmentIdAnswer",
            dataIndex: ["attachmentIdAnswer"],
            render: (attachmentIdAnswer, record) => (
                <>

                    {record.attachmentIdLetterToHudhur ? <a><Tag color="green" onClick={() => handleDownloadAttachment(record.attachmentIdLetterToHudhur)} icon={<DownloadOutlined/>}>Letter</Tag></a>: ""}

                    {record.attachmentIdSummary ?  <a><Tag color="red" onClick={() => handleDownloadAttachment(record.attachmentIdSummary)} icon={<DownloadOutlined/>}>Summary</Tag></a> : ""}

                    {record.attachmentIdAnswer ? <><br/><a><Tag onClick={() => handleDownloadAttachment(record.attachmentIdAnswer)} color="blue" icon={<DownloadOutlined/>}>Answer</Tag></a></> : ""}
                </>
            ),
        },
        {
            title: "Status",
            key: "status",
            dataIndex: ["internalStatus"],
            filters: letterStatusFilterDropdown,
            defaultFilteredValue : filtered ? ['LETTER_PRESENTED_TO_HUDHUR'] : ['LETTER_RECEIVED'],
            onFilter: (value, record) => record.internalStatus.includes(value),
            sorter: (a, b) => a.internalStatus.localeCompare(b.internalStatus),
            render: (status, record) => (
                <Button type="text" style={{backgroundColor: getProcessedLetterStatusColor(record), color: 'white'}}>
                    {getProcessedLetterStatusLabel(record)}
                </Button>
            ),
        },
        {
            title: "Date",
            dataIndex: ["originalLetter", "metaData", "created"],
            key: "date",
            defaultSortOrder: "descend",
            render: (date) => (date ? moment(date).format("YYYY-MM-DD") : "N/A"),
            sorter: (a, b) => a.originalLetter.metaData.created.localeCompare(b.originalLetter.metaData.created),
        },
        {
            title: "By",
            dataIndex: ["assignedTo"],
            key: "by",
        },
    ];
    
    const handleDownloadLettersForHudhur = () => {
        dispatch(downloadLettersForHudhur({
            finalData: {},
            callbackSuccess: (response) => {
                console.log("Download started", response);
                notification['success']({
                    message: 'Download started',
                    description:
                        'Download for Letters to Huzoor-e-Aqdas started.',
                });
            },
            callbackError: (error) => {
                console.error("Error downloading attachment", error);
                notification['error']({
                    message: 'Error downloading attachment ',
                    description:error,
                });
            }
        }));
    };

    const handleDownloadAttachment = (attachmentId) => {
        dispatch(downloadAttachment({
            finalData: {id: attachmentId},
            callbackSuccess: (response) => {
                console.log("Download started", response);
                notification['success']({
                    message: 'Download started',
                    description:
                        'Download for Attachment with '+attachmentId+' started.',
                });
            },
            callbackError: (error) => {
                console.error("Error downloading attachment", error);
                notification['error']({
                    message: 'Error downloading attachment ',
                    description:error,
                });
            }
        }));
    };
    
    return (
        <>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    <Col xs="24" xl={24}>
                        <Card
                            bordered={false}
                            className="criclebox tablespace mb-24"
                            title={filtered === "toHudhur" ?
                                <span>Letters going to Huzoor<sup>aa</sup></span> : "Letters Overview"}
                            extra={<Space>
                                {filtered === "toHudhur" ? <Button className={"ant-tag-blue"} onClick={handleDownloadLettersForHudhur}
                                                                   type="default"><FileWordOutlined/> Export as Word
                                </Button> : ""}
                            </Space>}
                        >
                            <div className="table-responsive" ref={componentRef}>
                                <Table
                                    columns={columnsLetters}
                                    dataSource={filtered === "toHudhur" ? lettersPresentToHudhur : letters}
                                    pagination={{pageSize: 10}}
                                    className="ant-border-space"
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: event => {
                                            }, // click row
                                            onDoubleClick: event => {
                                                navigateToLetterView(record)
                                            }, // double click row
                                            onContextMenu: event => {
                                            }, // right button click row
                                            onMouseEnter: event => {
                                            }, // mouse enter row
                                            onMouseLeave: event => {
                                            }, // mouse leave row
                                        };
                                    }}
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
            {/* TODO: Remove the below table */}
            {/* <div className="tabled">
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Card
                    bordered={false}
                    className="criclebox tablespace mb-24"
                    title="Letters Overview"
                >
                  <div className="table-responsive">
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={{pageSize: 10}}
                        className="ant-border-space"
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </div> */}
        </>
    );
}

export default LetterTable;